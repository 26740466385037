import { CheckIcon } from '@chakra-ui/icons';
import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	Button,
	FormControl,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
	useToast
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { changePassword } from '../../redux/actions/adminActions';
import PasswordField from '../PasswordField';

const PasswordChangeModal = ({ isOpen, onClose }) => {
	const dispatch = useDispatch();
	const { loading, error, passwordChange } = useSelector((state) => state.admin);
	const toast = useToast();

	useEffect(() => {
		if (passwordChange) {
			setTimeout(() => {
				onClose();
			}, '1000');

			toast({
				description: 'Password geaendert!',
				status: 'success',
				isClosable: true,
			});
		}
	}, [passwordChange, onClose, toast]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} size='lg'>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Ändere dein Passwort</ModalHeader>
				<ModalCloseButton />
				<Formik
					initialValues={{ currentPassword: '', password: '', confirmPassword: '' }}
					validationSchema={Yup.object({
						currentPassword: Yup.string()
							.min(1, 'Bitte gib das Passwort ein.')
							.required('Bitte gebe dein momentanes Passwort ein.'),
						password: Yup.string()
							.min(1, 'Passwort ist zu kurz.')
							.required('Bitte gebe ein neues Passwort ein.')
							.notOneOf(
								[Yup.ref('currentPassword'), null],
								'Neues Passwort darf nicht dem alten Passwort entsprechen.'
							),
						confirmPassword: Yup.string()
							.min(1, 'Passwort ist zu kurz.')
							.required('Bitte gebe ein neues Passwort ein.')
							.oneOf([Yup.ref('password'), null], 'Passwörter müssen übereinstimmen.'),
					})}
					onSubmit={(values) => {
						dispatch(changePassword(values.currentPassword, values.password));
					}}>
					{(formik) => (
						<Stack spacing='8'>
							<Box py='8' px='10'>
								<Stack spacing='6' as='form' onSubmit={formik.handleSubmit}>
									{error && (
										<Alert
											status='error'
											flexDirection='column'
											alignItems='center'
											justifyContent='center'
											textAlign='center'>
											<AlertIcon />
											<AlertTitle>Upps!</AlertTitle>
											<AlertDescription>{error}</AlertDescription>
										</Alert>
									)}
									<Stack spacing='5'>
										<FormControl>
											<PasswordField
												type='password'
												name='currentPassword'
												placeholder='Derzeitiges Passwort'
												label='Dein derzeitiges Passwort'
											/>
											<PasswordField
												type='password'
												name='password'
												placeholder='Neues Passwort'
												label='Neues Passwort'
											/>
											<PasswordField
												type='password'
												name='confirmPassword'
												placeholder='Bestätige neues Passwort'
												label='Bestätige neues Passwort'
											/>
										</FormControl>
									</Stack>
									<Stack spacing='6'>
										<Button
											colorScheme={passwordChange ? 'green' : 'ram'}
											fontWeight='md'
											size='lg'
											fontSize='md'
											isLoading={loading}
											type='submit'
											rightIcon={passwordChange ? <CheckIcon /> : null}>
											{passwordChange ? 'Password geändert' : 'Passwort ändern'}
										</Button>
									</Stack>
								</Stack>
							</Box>
						</Stack>
					)}
				</Formik>
			</ModalContent>
		</Modal>
	);
};

export default PasswordChangeModal;
