import {
  Button,
  Grid,
  GridItem,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Flex,
} from '@chakra-ui/react';
import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { MdLibraryBooks } from 'react-icons/md';
import TeilnehmerInfoModal from './TeilnehmerInfoModal';
import { useDisclosure } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { removeRueckbildungskursTeilnehmer } from '../../redux/actions/rueckbildungskursActions';

const RueckbildungskursTeilnehmerModal = ({ isOpen, onClose, kurs }) => {
  const dispatch = useDispatch();
  const {
    isOpen: teilnehmerInfoIsOpen,
    onOpen: teilnehmerInfoOnOpen,
    onClose: teilnehmerInfoOnClose,
  } = useDisclosure();

  const [teilnehmer, setTeilnehmer] = React.useState(null);

  const handleBookIconClick = (teilnehmer) => {
    setTeilnehmer(teilnehmer);
    teilnehmerInfoOnOpen();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Teilnehmer Rückbildungskurs{' '}
          <Flex>
            ({new Date(kurs.beginn).toLocaleDateString('de-DE').substring(0, 4)} -{' '}
            {new Date(kurs.ende).toLocaleDateString('de-DE')} )
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {kurs.teilnehmer.length > 0 ? (
            kurs.teilnehmer.map((teilnehmer) => (
              <Grid templateColumns='repeat(2, 1fr)' alignItems='center'>
                <GridItem>
                  <Text colSpan={4} my='3' minW='200px' noOfLines={1}>
                    {teilnehmer.fullName}
                  </Text>
                </GridItem>
                <GridItem colStart={5}>
                  <Button colorScheme='blue' mr='2' onClick={() => handleBookIconClick(teilnehmer)}>
                    <Icon as={MdLibraryBooks} />
                  </Button>
                </GridItem>
                <GridItem colStart={6}>
                  <Button
                    colorScheme='red'
                    onClick={() => dispatch(removeRueckbildungskursTeilnehmer(kurs._id, teilnehmer._id))}>
                    <Icon as={FaRegTrashAlt} />
                  </Button>
                </GridItem>
              </Grid>
            ))
          ) : (
            <Text>Keine Teilnehmer bisher</Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={onClose}>
            Schliessen
          </Button>
        </ModalFooter>
      </ModalContent>
      {teilnehmer && (
        <TeilnehmerInfoModal
          teilnehmer={teilnehmer}
          isOpen={teilnehmerInfoIsOpen}
          onClose={teilnehmerInfoOnClose}
          onOpen={teilnehmerInfoOnOpen}
        />
      )}
    </Modal>
  );
};

export default RueckbildungskursTeilnehmerModal;
