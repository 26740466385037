import axios from 'axios';
import {
  setError,
  setLoading,
  setAktuelles,
  setAktuellesRemovedFlag,
  setNewAktuellesFlag,
  setUpdateAktuellesFlag,
} from '../slices/aktuelles';

export const getAktuelles = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { data } = await axios.get(`/api/aktuelles`);
    dispatch(setAktuelles(data));
  } catch (error) {
    dispatch(
      setError(
        error.response && error.response.data
          ? error.response.data
          : error.message
          ? error.message
          : "Ein unbekannter Fehler ist aufgetreten, probier's ein andern mal"
      )
    );
  }
};

export const deleteAktuelles = (id) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const {
    admin: { adminInfo },
  } = getState();

  const config = { headers: { Authorization: `Bearer ${adminInfo.token}`, 'Content-Type': 'application/json' } };

  try {
    const { data } = await axios.delete(`api/aktuelles/${id}`, config);
    dispatch(setAktuelles(data));
    dispatch(setAktuellesRemovedFlag(true));
  } catch (error) {
    setError(
      error.response && error.response.data
        ? error.response.data
        : error.message
        ? error.message
        : "Ein unbekannter Fehler ist aufgetreten, probier's ein andern mal"
    );
  }
};

export const aktuellesRemovalFlag = (input) => (dispatch) => {
  dispatch(setAktuellesRemovedFlag(input));
};

export const aktuellesNewFlag = (input) => (dispatch) => {
  dispatch(setNewAktuellesFlag(input));
};

export const aktuellesUpdateFlag = (input) => (dispatch) => {
  dispatch(setUpdateAktuellesFlag(input));
};

export const createNewAktuellesEntry = (titel, text) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const {
    admin: { adminInfo },
  } = getState();

  const config = { headers: { Authorization: `Bearer ${adminInfo.token}`, 'Content-Type': 'application/json' } };

  try {
    const { data } = await axios.post(`api/aktuelles`, { titel, text }, config);
    dispatch(setAktuelles(data));
    dispatch(setNewAktuellesFlag(true));
  } catch (error) {
    dispatch(
    setError(
      error.response && error.response.data
        ? error.response.data
        : error.message
        ? error.message
        : "Ein unbekannter Fehler ist aufgetreten, probier's ein andern mal"
    ));
  }
};

export const updateAktuellesEntry = (id, titel, text) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const {
    admin: { adminInfo },
  } = getState();

  const config = { headers: { Authorization: `Bearer ${adminInfo.token}`, 'Content-Type': 'application/json' } };

  try {
    const { data } = await axios.put(`api/aktuelles/${id}`, { titel, text }, config);
    dispatch(setAktuelles(data));
    dispatch(setUpdateAktuellesFlag(true));
  } catch (error) {
    setError(
      error.response && error.response.data
        ? error.response.data
        : error.message
        ? error.message
        : "Ein unbekannter Fehler ist aufgetreten, probier's ein andern mal"
    );
  }
};
