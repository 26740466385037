import { Container, Divider, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React from 'react';

const UeberMich = () => {
  return (
    <>
      <Text fontSize='5xl' p='10' textAlign='center' fontFamily='Courgette' mt='32'>
        Über Mich
      </Text>
      <Divider m='auto' width='60px' border='3px solid #8D56A0' />
      <Container maxW='7xl' py='10'>
        <SimpleGrid minChildWidth='300px' spacing='10px'>
          <Stack spacing='5' align={{ base: 'center' }}>
            <Text fontSize='3xl' pb='10px'>
              Herzlich willkommen liebe werdende Eltern
            </Text>
            <Text>Ich bin am 04.12.1985 geboren und im Unterfreiamt in Fischbach-Göslikon aufgewachsen.</Text>
            <Image
              display={{ lg: 'none' }}
              boxSize='450px'
              objectFit='cover'
              rounded='md'
              src='./images/ramona-portrait.webp'
            />
            <Text>
              Im Jahr 2007 hab ich die höhere Fachschule zur diplomierten Pflegefachfrau HF abgeschlossen und
              anschliessend bis ins Jahr 2011 auf dem Beruf auf einer medizinischen & onkologischen Abteilung in der
              Hirslandenklinik Aarau gearbeitet. Obwohl mir der Beruf als Pflegefachfrau immer sehr gefiel und ich meine
              Fähigkeiten und Fertigkeiten darin ausüben konnte, kam ich von meinem eigentlichen Berufswunsch der
              Hebamme nicht ab. Im Gegenteil, von Jahr zu Jahr nahm die Begeisterung und das Interesse zu und ich begann
              im Februar 2011 das zweijährige Vollzeitstudium an der Berner Fachhochschule zur Hebamme Bachelor of
              Science.
            </Text>
            <Text>
              Ich trat somit in die Fussstapfen meiner lieben Grossmutter, welche Jahrzehnte lang als freipraktizierende
              Hebamme im Raum Bremgarten tätig war. Ab März 2013 bin ich als dipl. Hebamme FH im Gebärsaal der
              Hirslandenklinik Aarau tätig. Nebenbei habe ich diverse Weiterbildungen (geburtshilfliche Akupunktur,
              K-Taping, BeBo-Kursleiterin, traditionelle Hebammenkunst) absolviert. Seit Oktober 2022 habe neu die
              Funktion als Co-Leitung Gebärsaal übernommen.
            </Text>
            <Text>
              Im August 2014 heiratete ich meinen Mann Dominik und Schlag auf Schlag erblickte unsere Tochter "Eleya" am
              16.03.2015 das Licht der Welt. Unsere 2. Tochter "Joline" erweiterte am 11.03.2017 unsere Familie.
            </Text>
            <Text>
              Nebst der Familie und Beruf bin ich schon seit bald 20 Jahren eine leidenschaftliche Turnerin des
              Damenturnverein Fi-Gö`s. Nebst kleineren Ämtli`s wie J&S Coach bin ich noch im Vorstand des
              Kreisturnverbandes Freiamt.
            </Text>
          </Stack>
          <Image
            display={{ base: 'none', lg: 'block' }}
            boxSize='450px'
            objectFit='cover'
            rounded='md'
            src='./images/ramona-portrait.webp'
          />
        </SimpleGrid>
      </Container>
    </>
  );
};

export default UeberMich;
