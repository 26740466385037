import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  Stack,
  SimpleGrid,
  Select,
  Flex,
  Text,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import TextField from '../TextField';
import {} from '@chakra-ui/react';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { enrollRueckbildungskurs, getRueckbildungskurse } from '../../redux/actions/rueckbildungskursActions';

function RueckbildungsForm({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const { loading, error, rueckbildungskursInfo, kursEnrolledFlag } = useSelector((state) => state.rueckbildungskurs);

  const columWidth = '280px';
  const toast = useToast();

  useEffect(() => {
    dispatch(getRueckbildungskurse());
    if (kursEnrolledFlag) {
      onClose();

      toast({
        description: 'Erfolgreich eingeschrieben!',
        status: 'success',
        isClosable: true,
      });
    }
  }, [dispatch, kursEnrolledFlag, onClose, toast]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size='full'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Anmeldung</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                fullName: '',
                email: '',
                address: '',
                postalCode: '',
                city: '',
                phone: '',
                dob: '',
                birthModus: '',
                childName: '',
                childDob: '',
                courseDate: '',
              }}
              validationSchema={Yup.object({
                email: Yup.string().email('Ungültige Email.').required('Email Addresse ist erforderlich.'),
                fullName: Yup.string().required('Vor & Nachname ist erforderlich.'),
                address: Yup.string().required('Adresse ist erforderlich.'),
                postalCode: Yup.string().required('Postleitzahl ist erforderlich.'),
                city: Yup.string().required('Stadt ist erforderlich.'),
                phone: Yup.string().required('Telefonnummer ist erforderlich.'),
                dob: Yup.string().required('Geburtsdatum ist erforderlich.'),
                courseId: Yup.string().required('Kurstermin ist erforderlich.'),
                birthModus: Yup.string(),
                childDob: Yup.string().required('Geburtsdatum des Kindes ist erforderlich.'),
              })}
              onSubmit={(values) => {
                dispatch(
                  enrollRueckbildungskurs({
                    email: values.email,
                    fullName: values.fullName,
                    address: values.address,
                    phone: values.phone,
                    dob: values.dob,
                    childDob: values.childDob,
                    courseId: values.courseId,
                    birthModus: values.birthModus,
                    city: values.city,
                    postalCode: values.postalCode,
                  })
                );
              }}>
              {(formik) => (
                <Stack spacing='0'>
                  <Box py='8' px='0'>
                    <Stack spacing='6' as='form' onSubmit={formik.handleSubmit}>
                      {error && (
                        <Alert
                          status='error'
                          flexDirection='column'
                          alignItems='center'
                          justifyContent='center'
                          textAlign='center'>
                          <AlertIcon />
                          <AlertTitle>Upps!</AlertTitle>
                          <AlertDescription>{error}</AlertDescription>
                        </Alert>
                      )}
                      <Stack spacing='5' maxW='5xl'>
                        <FormControl>
                          <SimpleGrid spacing='4' minChildWidth={columWidth}>
                            <TextField type='text' name='fullName' placeholder='' label='Vollständiger Name*' />
                            <TextField type='text' name='email' placeholder='' label='Email*' />
                          </SimpleGrid>
                          <SimpleGrid spacing='4' minChildWidth={columWidth}>
                            <TextField type='text' name='phone' placeholder='' label='Telefon / Natel*' />
                            <TextField type='text' name='address' placeholder='' label='Wohnadresse*' />
                          </SimpleGrid>
                          <SimpleGrid spacing='4' minChildWidth={columWidth}>
                            <TextField type='text' name='postalCode' placeholder='' label='Postleitzahl*' />
                            <TextField type='text' name='city' label='Stadt*' />
                          </SimpleGrid>
                          <SimpleGrid spacing='4' minChildWidth={columWidth}>
                            <TextField type='date' name='dob' placeholder='' label='Geburtsdatum*' />
                            <TextField type='date' name='childDob' label='Geburtsdatum des Kindes*' />
                          </SimpleGrid>
                          <SimpleGrid mt='2' spacing='4' minChildWidth={columWidth}>
                            <TextField
                              type='text'
                              name='birthModus'
                              placeholder=''
                              label='Geburtsmodus (Kaiserschnitt, Vakuum, Vaginal)'
                            />
                            <Flex direction='column'>
                              <Text mb='7px'>Kurstermin*</Text>
                              <Select
                                placeholder='Wähle deinen Kurstermin'
                                label='Kurstermin'
                                name='courseId'
                                value={formik.values.courseId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.courseId && formik.errors.courseId}
                                errorBorderColor='red.400'
                                border='1px'>
                                {rueckbildungskursInfo &&
                                  rueckbildungskursInfo
                                    .filter((kurs) => kurs.aktiv)
                                    .map((kurs) => (
                                      <option
                                        key={kurs._id}
                                        value={kurs._id}
                                        disabled={kurs.teilnehmer.length >= kurs.maxTeilnehmerAnzahl}
                                        hidden={!kurs.aktiv}>
                                        {new Date(kurs.beginn).toLocaleDateString('de-DE')} -{' '}
                                        {new Date(kurs.ende).toLocaleDateString('de-DE')}
                                        {` `}
                                        {kurs.teilnehmer.length >= kurs.maxTeilnehmerAnzahl ? '(ausgebucht)' : ''}
                                      </option>
                                    ))}
                              </Select>
                              <Text pt='2' pl='1' textColor='red.500' fontSize='sm'>
                                {formik.errors.courseId}
                              </Text>
                            </Flex>
                          </SimpleGrid>
                          <SimpleGrid mt='2' spacing='4' minChildWidth={columWidth}></SimpleGrid>
                          <SimpleGrid spacing='4' minChildWidth={columWidth}></SimpleGrid>
                          <SimpleGrid mt='6' spacing='4'>
                            <Button
                              colorScheme='ram'
                              fontWeight='md'
                              size='lg'
                              fontSize='md'
                              isLoading={loading}
                              type='submit'>
                              Abschicken
                            </Button>
                            <Button colorScheme='gray' fontWeight='md' size='lg' fontSize='md' onClick={onClose}>
                              Abbrechen
                            </Button>
                          </SimpleGrid>
                          <Spacer />
                        </FormControl>
                      </Stack>
                      <Stack spacing='6'></Stack>
                    </Stack>
                  </Box>
                </Stack>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default RueckbildungsForm;
