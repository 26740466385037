import {
  Button,
  Image,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { kursEnrolledFlag } from '../../redux/actions/vorbereitungsKursActions';
import VorbereitungskursForm from './VorbereitungskursForm';
import { showVorberereitungskursAdmin } from '../../config';

const VorbereitungsKursInfoModal = ({ isOpen, onClose }) => {
  const { isOpen: isFormOpen, onOpen, onClose: onFormClose } = useDisclosure();
  const dispatch = useDispatch();

  const modalClose = () => {
    onClose();
  };

  useEffect(() => {
    dispatch(kursEnrolledFlag(false));
  });

  const modalOpen = () => {
    onOpen();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl' isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Geburtsvorbereitungskurs</ModalHeader>
        <Image objectFit='cover' src='images/vorbereitung.jpg' />
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing='9'>
            <Stack>
              <Text>Zurzeit biete ich Einzelkurse (mit Partner) bei mir im Praxisraum oder bei Ihnen zu Hause an.</Text>
              <Text>
                Im Geburtsvorbereitungskurs erhalten Sie Informationen zu Schwangerschaft, Geburt, Wochenbett, dem
                Stillen und die erste Zeit mit Ihrem Baby. Dazu gehört:
              </Text>
            </Stack>

            <List spacing={3}>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='green.500' />
                Vorbereitung auf die Geburt
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='green.500' />
                Geburtsbeginnzeichen
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='green.500' />
                Atem- und Entspannungsübungen für die Zeit der Wehen
              </ListItem>

              <ListItem>
                <ListIcon as={MdCheckCircle} color='green.500' />
                Verlauf einer Geburt (vaginal oder Kaiserschnitt)
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='green.500' />
                Möglichkeiten der Schmerzlinderung (Anästhesie, alternative Therapien)
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='green.500' />
                Rolle Ihres Partners während der Geburt
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='green.500' />
                Wochenbett
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='green.500' />
                Stillen und die ersten Tage mit Ihrem Baby
              </ListItem>
            </List>
            <Stack>
              <Text>Natürlich wird der Kurs nach ihren Vorstellungen und Fragen gestaltet.</Text>
              <Text>Kursdauer: Individuell 2-3 Stunden</Text>
              <Text>Kurskosten: CHF 60.- pro 30 Minuten </Text>
              <Text>Die Krankenkasse uebernimmt einen Pauschalbetrag von CHF 150.-.</Text>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          {showVorberereitungskursAdmin && (
            <Button mr='2' colorScheme='ram' onClick={modalOpen}>
              Anmeldeformular
            </Button>
          )}
          <VorbereitungskursForm isOpen={isFormOpen} onClose={onFormClose} />
          <Button mr={3} onClick={() => modalClose()}>
            Schliessen
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default VorbereitungsKursInfoModal;
