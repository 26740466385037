import React from 'react';
import { Button } from '@chakra-ui/button';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { useDispatch } from 'react-redux';
import { Text } from '@chakra-ui/layout';

const ConfirmModal = ({ isOpen, onClose, title, btnText, action, id }) => {
  const [btnLoading, setBtnLoading] = React.useState(false);
  const dispatch = useDispatch();
  const confirmAction = () => {
    setBtnLoading(true);
    dispatch(action(id));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{btnText}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{title}</Text>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme='red'
            mr={3}
            onClick={() => confirmAction()}
            isLoading={btnLoading}
            loadingText='Lösche Kurs...'
            fontWeight='md'>
            {btnText}
          </Button>
          <Button variant='ghost' onClick={onClose}>
            Abbrechen
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
