import { Avatar, HStack, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { Link as ReactLink } from 'react-router-dom';

const AdminMenu = ({ adminInfo, logoutHandler }) => (
	<Menu fontSize='md'>
		<MenuButton rounded='md' variant='link' cursor='pointer'>
			<HStack>
				<Avatar bg='ram.600' name={adminInfo.name} src='https://bit.ly/broken-link' />
			</HStack>
		</MenuButton>
		<MenuList z-index='7'>
			<MenuDivider />
			<MenuItem as={ReactLink} to='/admin-console'>
				<MdOutlineAdminPanelSettings />
				<Text ml='2' fontSize='md'>
					Admin Console
				</Text>
			</MenuItem>
			<MenuDivider />
			<MenuItem onClick={logoutHandler} fontSize='md'>
				Logout
			</MenuItem>
		</MenuList>
	</Menu>
);

export default AdminMenu;
