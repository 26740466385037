import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { kursEnrolledFlag } from '../../redux/actions/vorbereitungsKursActions';
import RueckbildungsForm from './RueckbildungsForm';

const RueckbildungsInfoModal = ({ isOpen, onClose }) => {
  const { isOpen: isFormOpen, onOpen, onClose: onFormClose } = useDisclosure();
  const dispatch = useDispatch();

  const modalClose = () => {
    onClose();
  };

  useEffect(() => {
    dispatch(kursEnrolledFlag(false));
  });

  const modalOpen = () => {
    onOpen();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl' isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Rückbildungs- und Beckenbodenkurs</ModalHeader>
        <Image objectFit='cover' src='images/rueckbildung.jpg' />
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing='9'>
            <Stack>
              <Text>
                Langsam sollte sich die junge Mutter wieder in ihrem Körper, der nun wieder ihr allein gehört,
                zurechtfinden. Im Rückbildungsturnen wird die körperliche Leistungsfähigkeit nach der Geburt langsam
                wieder gesteigert. Besonders intensiv trainieren wir die Beckenbodenmuskulatur, um mögliche Spätfolgen
                der Schwangerschaft (Inkontinenz, Gebärmuttersenkung) zu vermeiden. Aber auch allgemeine Kräftigungs-,
                Haltungs- und Dehnungsübungen sind Bestandteile der Lektionen, wie auch Entspannungsübungen und
                Theorieinputs.
              </Text>
              <Text>
                Das Training kann frühestens ab der 6. Woche nach der Geburt besucht werden und nach einem Kaiserschnitt
                frühestens nach 8 Wochen.
              </Text>
              <Text>
                Für den Besuch eines Rückbildungs- und Beckenbodenkurses ist es nie zu spät, auch wenn sie vor mehreren
                Monaten geboren haben.
              </Text>
              <Text>
                Ein Kurs findet einmal wöchentlich über 8 Wochen statt und wird aufbauend und abwechslungsreich
                gestaltet. Damit ihr euch völlig auf euch und euren Körper konzentrieren könnt, ist das Mitnehmen von
                Babys nicht erwünscht.
              </Text>
            </Stack>

            <Stack>
              <Text>Kursdauer: 8 x 75 Minuten</Text>
              <Text>Kursort: Gymnastikraum, Oberdorfstrasse 2, 5525 Fischbach-Göslikon</Text>
              <Text>Kurskosten: CHF 250.- (Ihre Krankenkasse übernimmt ev. ein Teil der Kurskosten) </Text>
              <Text>Bequeme Kleidung, barfuss oder Socken und Frottée- oder Badetuch bitte mitbringen</Text>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button mr='2' colorScheme='ram' onClick={modalOpen}>
            Anmeldeformular
          </Button>
          <RueckbildungsForm isOpen={isFormOpen} onClose={onFormClose} />
          <Button mr={3} onClick={() => modalClose()}>
            Schliessen
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RueckbildungsInfoModal;
