import {
  Button,
  Divider,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

const DatenschutzModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <VStack spacing='5' p='4' minHeight='90vh'>
          <Text fontSize='5xl' fontFamily='Courgette'>
            Datenschutz
          </Text>
          <Divider width='60px' border='3px solid #8D56A0' />
          <Text fontSize='xl'>Willkommen auf meiner Website</Text>
          <Text>
            Die nachfolgende Datenschutzerklärung gilt für die Nutzung dieser Website sowie der darauf angebotenen
            Dienstleistungen. Die vorliegende Datenschutzerklärung informiert Sie über die Erhebung personenbezogener
            Daten während Ihres Besuchs auf dieser Website. Mit der Nutzung dieser Website erklären Sie sich mit der
            Bearbeitung Ihrer Personendaten gemäss der vorliegenden Datenschutzerklärung einverstanden.
          </Text>
          <Text>
            Die Erhebung und die Verarbeitung Ihrer personenbezogenen Daten geschehen unter Beachtung der geltenden
            datenschutzrechtlichen Gesetze und Anforderungen. Im Übrigen lehnt die Hebamme Ramona Guhl jegliche Haftung
            bei der Nutzung dieser Website ab.
          </Text>
        </VStack>
        <ModalFooter>
          <Button onClick={onClose}>Schliessen</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DatenschutzModal;
