import axios from 'axios';
import {
  setError,
  setLoading,
  adminLogin,
  adminLogout,
  setPasswordChange,
  reset,
  setTokenState,

} from '../slices/admin';

export const login = (email, password) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const config = { headers: { 'Content-Type': 'application/json' } };
    const { data } = await axios.post('api/admin/login', { email, password }, config);

    dispatch(adminLogin(data));
    localStorage.setItem('adminInfo', JSON.stringify(data));
  } catch (error) {
    dispatch(
      setError(
        error.response && error.response.data
          ? error.response.data
          : error.message
          ? error.message
          : "Ein unbekannter Fehler ist aufgetreten, probier's ein andern mal"
      )
    );
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem('adminInfo');
  dispatch(adminLogout());
};

export const resetState = () => (dispatch) => {
  dispatch(reset());
};

export const setPasswordChangeFlag = (input) => (dispatch) => {
  dispatch(setPasswordChange(input));
};

export const changePassword = (currentPassword, password) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const {
    admin: { adminInfo },
  } = getState();
  try {
    const { token } = adminInfo;
    const config = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } };

    await axios.post(`/api/admin/password-change`, { currentPassword, password }, config);
    dispatch(setPasswordChange(true));
  } catch (error) {
    dispatch(
      setError(
        error.response && error.response.data
          ? error.response.data
          : error.message
          ? error.message
          : "Ein unbekannter Fehler ist aufgetreten, probier's ein andern mal"
      )
    );
  }
};

export const isTokenValid = () => async (dispatch, getState) => {
  const {
    admin: { adminInfo },
  } = getState();
  try {
    const { token } = adminInfo;
    const config = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } };
    const { data } = await axios.get('api/admin/validate-token', config);
    dispatch(setTokenState(data));
  } catch (error) {}
};
