export const ktapingContent = [
  'Die Schwangerschaft ist eine besondere Zeit im Leben einer Frau, geprägt von vielen Veränderungen im Körper der Mutter. Statische Veränderungen durch den grösser werdenden Bauch führen sehr oft zu Überbelastung der Bauch- und Rückenmuskulatur und somit vielfach zu Problemen in der LWS-Region. Die hormonell bedingte Lockerung und die Überbelastung im ISG-Bereich führen auch oftmals zu einer Schmerzproblematik.',
  'Die Anwendung von Medikamenten ist durch das Gefahrenpotential für das Baby meist nicht möglich.',
  'K-Tapes wirken auf vollkommen natürliche Weise und kommen ohne jegliche Medikamente oder chemische Hilfsmittel aus. Sie sind kaum spürbar, stören nicht den Bewegungsablauf, sind schnell aufgebracht und später ebenso mühelos wieder entfernt.',
  'Das kinesiologische Tape ist den Eigenschaften der Haut nachempfunden und übermittelt dadurch positive sensorische Informationen an unseren Körper.',
  'Auch bei Rückbildungs- und Stillproblematiken kann das K-Tape zum Einsatz kommen.',
  'Wirkweisen der K-Taping-Therapie:',
  [
    'Verbesserung der Muskelfunktion',
    'Entfernung der Zirkulationseinschränkung (Hämatom, Lymphstau, Schwellung)',
    'Schmerzreduzierung',
    'Unterstützung der Gelenkfunktion',
  ],
  'Bei folgenden Beschwerden kann ich bei Ihnen das K-Taping anwenden:',
  [
    'Karpaltunnelsyndrom',
    'Rückenbeschwerden',
    'Symphysenbeschwerden',
    'Verzögerte Uterusrückbildung',
    'Milchstau',
    'Verdauungsprobleme',
  ],
];

export const wochenbettbetreuungContent = [
  `Die Zeit nach der Geburt ist für Mutter und Kind wie auch für den Vater und die Familie prägend.
  Gerne begleite ich Sie in dieser "sensiblen Phase" im Rahmen von Hausbesuchen zur Pflege und Überwachung des Gesundheitszustandes von Ihnen und ihrem Baby sowie zur Unterstützung, Anleitung und Beratung in der Pflege und Ernährung Ihres Kindes.`,
  'Jede Frau hat Anspruch auf eine Hebamme in den ersten 56 Tagen nach der Geburt (max. 10-16 Hausbesuche), falls nötig auch länger mit ärztlicher Verordnung. Die Besuche werden von der Krankenkasse aus der Grundversicherung bezahlt.',
  'Weil die Geburt und die Wochenbettbetreuung zeitlich nicht geplant werden können, stehe ich als Hebamme für die Betreuung einer Frau nach der Geburt in Abruf-Bereitschaft und dies für mehrere Wochen (ab 3 Wochen vor dem Geburtstermin bis 2 Wochen danach). Zudem bin ich ab dem Zeitpunkt nach der Geburt, wenn sie mit ihrem Baby zu Hause sind, rund um die Uhr für Sie erreichbar, auch an den Wochenenden und Feiertagen.',
  'Bei Ferienabwesenheit oder Krankheit organisiere ich eine Stellvertretung.',
  'Für diese Leistung verlange ich wie die anderen Hebammen vom Kanton Aargau ein Pikettgeld von 120.- CHF, welches Sie im Voraus bezahlen.',
  'Diese Pikettentschädigung für die Betreuung im Wochenbett wird in Form einer Pauschale abgegolten und wird von der Grundversicherung nicht bezahlt.',
  'Es gibt Wohngemeinden, die diese Kosten übernehmen. Fragen Sie doch bei ihrer Wohngemeinde nach.',
  'Damit ich Sie in Ihrer "Wochenbettzeit" betreue, ist eine Anmeldung erforderlich. (siehe Kontakt)',
];

export const hebammengespraechContent = [
  'Wenn Sie Fragen, Ängste oder Sorgen in Zusammenhang mit der Schwangerschaft, Geburt und Wochenbett haben, so biete ich auch ein Hebammengespräch an. ',
  `Das Hebammengespräch beinhaltet nebst Informationen rund um Schwangerschaft, Geburt und Wochenbett auch Themen, welche die soziale Situation betreffen oder den Rollenwechsel Mutter-Vater-Eltern werden.
  Mehrgebärende Frauen haben häufig das Bedürfnis über ihre Geburtserfahrungen zu sprechen, insbesondere wenn unverarbeitete Erlebnisse früherer Geburten ihre Befindlichkeit belasten.`,
  'Wenn Sie ein Hebammengespräch wünschen, so melden Sie sich bei mir (siehe Kontakt).',
  'Kosten: CHF 60.- pro 30 Minuten',
];

export const schwangerschaftsbetreuungContent = [
  'Die Hebammensprechstunde bietet Ihnen eine ganzheitliche und individuelle Betreuung, in der Schwangerschaft, in der Vorbereitung auf die Geburt und für die Zeit danach.',
  `Ich nehmen mir für Sie Zeit.
  Jede Schwangerschaft, Geburt und Wochenbettzeit ist anders, daher ist es für mich zentral, dass ich auf Ihre spezifischen Bedürfnisse und Anliegen eingehen kann. In der Hebammensprechstunde biete ich folgende Themen an:`,
  [
    'Schwangerschaftskontrollen mit ausführlicher Beratung, in Absprache mit Ihrer Ärztin oder Ihrem Arzt',
    'Besprechung der verschiedenen Geburtsarten und deren Vor- und Nachteile',
    'Hebammenberatung bei speziellen Fragen oder Anliegen',
  ],
];

export const akupunkturContent = ['Kommt bald....'];
