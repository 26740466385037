import { Heading, Icon, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { TiInfoOutline } from 'react-icons/ti';

const AktuellesCard = ({ info }) => {
  const { text, titel } = info;
  return (
    info && (
      <VStack
        textAlign='center'
        p='4'
        border='1px'
        borderColor='ram.200'
        boxShadow='2xl'
        roundedBottomRight='3xl'
        roundedTopStart='3xl'
        w='320px'
        h='420px'
        maxH='500px'>
        <Icon as={TiInfoOutline} boxSize={16} color='ram.600' />
        <Heading py='3' size='md'>
          {titel}
        </Heading>
        <Text>{text}</Text>
      </VStack>
    )
  );
};

export default AktuellesCard;
