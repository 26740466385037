import { PhoneIcon } from '@chakra-ui/icons';
import { Box, Divider, Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import React from 'react';
import { IoMailSharp } from 'react-icons/io5';
import { PiMapPinFill } from 'react-icons/pi';
import KontaktForm from './KontaktForm';

const Kontakt = () => {
	return (
		<Box px='5' mb='20'>
			<Text fontSize='5xl' p='10' textAlign='center' fontFamily='Courgette' mt='32'>
				Kontakt
			</Text>
			<Divider m='auto' width='60px' border='3px solid #8D56A0' />
			<SimpleGrid minChildWidth={{ base: '270px', sm: '400px', md: '400px' }} spacing='14' maxW='7xl' m='auto' mt='10'>
				<Box border='#d4b4df'>
					<Heading></Heading>
					<Text py='5'>
						Zögere nicht mich zu kontaktieren. Ich freue mich auf deine Nachricht oder ein angenehmes Gespräch.
					</Text>
					<Text>
						Falls du nicht innerhalb von 48h eine Antwort erhältst (Abwesenheiten ausgenommen), kontrolliere bitte
						deinen Spam Ordner oder melde dich telefonisch bei mir.
					</Text>
					<Flex alignItems='center' fontSize='xl' mt='20'>
						<PhoneIcon color='#8D56A0' mr='2' />
						<Text>(+41) 79 548 96 50</Text>
					</Flex>
					<Flex alignItems='center' fontSize='xl' mt='4'>
						<IoMailSharp color='#8D56A0' mr='2' />
						<Text ml='2'>ramonaguhl@hebamme-hin.ch</Text>
					</Flex>
					<Flex alignItems='center' fontSize='xl' mt='4'>
						<PiMapPinFill color='#8D56A0' />
						<Text ml='2'>Oberdorfstrasse 2, 5525 Fischbach-Göslikon</Text>
					</Flex>
				</Box>
				<Box>
					<KontaktForm />
				</Box>
			</SimpleGrid>
		</Box>
	);
};

export default Kontakt;
