import { combineReducers, configureStore } from '@reduxjs/toolkit';

import admin from './slices/admin';
import aktuelles from './slices/aktuelles';
import vorbereitungsKurse from './slices/vorbereitungsKurse';
import rueckbildungskurs from './slices/rueckbildungskurs';

const reducer = combineReducers({
  admin,
  aktuelles,
  vorbereitungsKurse,
  rueckbildungskurs,
});

export default configureStore({ reducer });
