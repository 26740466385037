import { Box, Text, Wrap, WrapItem, Divider } from '@chakra-ui/react';
import DownloadCard from './DownloadCard';

const DocumentsDownload = () => {
  return (
    <Box px='5' mb='20'>
      <Text fontSize='5xl' p='10' textAlign='center' fontFamily='Courgette' mt='32'>
        Dokumente
      </Text>
      <Divider m='auto' width='60px' border='3px solid #8D56A0' />
      <Wrap pt='12' px='4' display='flex' justifyContent='center' spacing='10' align='center' justify='center'>
        <WrapItem display='table-column'>
          <DownloadCard url='Begleit-Brief-Pikettgeld.pdf' title='Hebammenbetreuung im Wochenbett' />
        </WrapItem>
        <WrapItem display='table-column'>
          <DownloadCard url='Fragebogen_RB.docx' title='Rückbildungskurs Fragebogen' />
        </WrapItem>
        <WrapItem display='table-column'>
          <DownloadCard url='Personalienformular_leer.docx' title='Personalienformular' />
        </WrapItem>
        <WrapItem display='table-column'>
          <DownloadCard url='SHV_3_Einwilligungserklaerung-Patientenformular[8889].docx' title='Patientenformular' />
        </WrapItem>
        <WrapItem display='table-column'>
          <DownloadCard
            url='Statistik_und_Qualitätmanagement_SHV.pdf'
            title='Information Hebammenverband'
            subtitle='Statistik & Qualitätsmanagement'
          />
        </WrapItem>
        <WrapItem display='table-column'>
          <DownloadCard url='WoBe_Brief.docx' title='Information Wochenbett' />
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export default DocumentsDownload;
