import axios from 'axios';
import {
  setError,
  setLoading,
  setRueckbildungskurs,
  setKursUpdateFlag,
  setKursEnrolledFlag,
  setKursNewFlag,
  setKursRemovedFlag,
  reset,
} from '../slices/rueckbildungskurs';

export const getRueckbildungskurse = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { data } = await axios.get('api/rueckbildungskurs');

    dispatch(setRueckbildungskurs(data));

    localStorage.setItem('rueckbildungskursInfo', JSON.stringify(data));
  } catch (error) {
    dispatch(
      setError(
        error.response && error.response.data
          ? error.response.data
          : error.message
          ? error.message
          : "Ein unbekannter Fehler ist aufgetreten, probier's ein andern mal"
      )
    );
  }
};

export const kursNewFlagReset = (input) => async (dispatch) => {
  dispatch(setKursNewFlag(input));
};

export const createNewRueckbildungskurs = (kurs) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const {
    admin: { adminInfo },
  } = getState();

  try {
    const { token } = adminInfo;
    const config = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } };

    const { data } = await axios.post('api/rueckbildungskurs', kurs, config);

    dispatch(setRueckbildungskurs(data));
    dispatch(setKursNewFlag(true));
    localStorage.setItem('rueckbildungskursInfo', JSON.stringify(data));
  } catch (error) {
    dispatch(
      setError(
        error.response && error.response.data
          ? error.response.data
          : error.message
          ? error.message
          : "Ein unbekannter Fehler ist aufgetreten, probier's ein andern mal"
      )
    );
  }
};

export const resetState = () => async (dispatch) => {
  dispatch(reset());
};

export const updateRueckbildungskurs = (kurs) => async (dispatch, getState) => {
  dispatch(setLoading(true));

  const {
    admin: { adminInfo },
  } = getState();

  try {
    const { token } = adminInfo;
    const config = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } };

    const { data } = await axios.put(`api/rueckbildungskurs`, kurs, config);

    dispatch(setRueckbildungskurs(data));
    dispatch(setKursUpdateFlag(true));

    localStorage.setItem('rueckbildungskursInfo', JSON.stringify(data));
  } catch (error) {
    dispatch(
      setError(
        error.response && error.response.data
          ? error.response.data
          : error.message
          ? error.message
          : "Ein unbekannter Fehler ist aufgetreten, probier's ein andern mal"
      )
    );
  }
};

export const kursUpdateFlag = (input) => async (dispatch) => {
  dispatch(setKursUpdateFlag(input));
};

export const enrollRueckbildungskurs = (teilnehmer) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    await axios.post(`api/rueckbildungskurs/enroll`, { teilnehmer });

    dispatch(setKursEnrolledFlag(true));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(
      setError(
        error.response && error.response.data
          ? error.response.data
          : error.message
          ? error.message
          : "Ein unbekannter Fehler ist aufgetreten, probier's ein andern mal"
      )
    );
  }
};

export const kursEnrolledFlag = (input) => async (dispatch) => {
  dispatch(setKursEnrolledFlag(input));
};

export const removeRueckbildungskursTeilnehmer = (courseId, teilnehmerId) => async (dispatch, getState) => {
  dispatch(setLoading(true));

  const {
    admin: { adminInfo },
  } = getState();

  try {
    const { token } = adminInfo;

    const config = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } };

    const { data } = await axios.delete(`api/rueckbildungskurs/remove`, {
      ...config,
      data: { courseId, teilnehmerId },
    });

    dispatch(setRueckbildungskurs(data));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(
      setError(
        error.response && error.response.data
          ? error.response.data
          : error.message
          ? error.message
          : "Ein unbekannter Fehler ist aufgetreten, probier's ein andern mal"
      )
    );
  }
};

export const deleteRueckbildungskurs = (id) => async (dispatch, getState) => {
  dispatch(setLoading(true));

  const {
    admin: { adminInfo },
  } = getState();

  try {
    const { token } = adminInfo;

    const config = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } };

    const { data } = await axios.delete(`api/rueckbildungskurs`, { ...config, data: { id } });

    dispatch(setRueckbildungskurs(data));
    dispatch(setLoading(false));
    dispatch(setKursRemovedFlag(true));
  } catch (error) {
    dispatch(
      setError(
        error.response && error.response.data
          ? error.response.data
          : error.message
          ? error.message
          : "Ein unbekannter Fehler ist aufgetreten, probier's ein andern mal"
      )
    );
  }
};
