import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Flex,
  IconButton,
  Image,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import ImpressumModal from './modals/ImpressumModal';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { logout, resetState, isTokenValid } from '../redux/actions/adminActions';
import DatenschutzModal from './modals/DatenschutzModal';
import LoginModal from './modals/LoginModal';

const Footer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { loading, error, adminInfo, tokenValid } = useSelector((state) => state.admin);
  const { isOpen: adminIsOpen, onOpen: adminOnOpen, onClose: adminOnClose } = useDisclosure();
  const { isOpen: datenschutzIsOpen, onOpen: datenschutzOnOpen, onClose: datenschutzOnClose } = useDisclosure();
  const { isOpen: impressumIsOpen, onOpen: impressumOnOpen, onClose: impressumOnClose } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    dispatch(isTokenValid());
    if (adminInfo && tokenValid) {
      adminOnClose();
      dispatch(resetState());
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 500);
    }
  }, [adminInfo, error, location.state, adminOnClose, loading, dispatch, toast, tokenValid]);

  const logoutHandler = () => {
    dispatch(logout());
    toast({
      description: 'Erfolgreich ausgeloggt.',
      status: 'success',
      isClosable: 'true',
    });
  };

  const adminLoginOpen = () => {
    adminOnOpen();
  };

  return (
    <Box w='100%' bg='#d4b4df'>
      <Container as='footer' maxW='7xl'>
        <Stack
          spacing='8'
          direction={{ base: 'column', md: 'row' }}
          justify='space-between'
          py={{ base: '12', md: '16' }}>
          <Stack spacing={{ base: '6', md: '8' }} align='start'>
            <Flex alignItems='center' fontFamily='Courgette'>
              <Image
                src='./images/logo-ramona.webp'
                boxSize={{ base: '60px', md: '70px', lg: '80px', xl: '100px' }}
                objectFit='cover'
              />
              <Text pb='12' pl='2'>
                Hebamme
              </Text>
              <Text minWidth='190px' noOfLines={1} pt='5' ml='-12'>
                Ramona Guhl
              </Text>
            </Flex>
            <Text color='muted'>Diplom Hebamme</Text>
          </Stack>
          <Stack direction={{ base: 'column-reverse', md: 'column', lg: 'row' }} spacing={{ base: '12', md: '8' }}>
            <Stack direction={{ base: 'column', md: 'row' }} spacing='8'>
              <Stack spacing='4' minW='36'>
                <Stack spacing='3' shouldWrapChildren>
                  <Button variant='link' onClick={impressumOnOpen} fontWeight='md'>
                    Impressum
                  </Button>
                  <ImpressumModal isOpen={impressumIsOpen} onClose={impressumOnClose} onOpen={impressumOnOpen} />
                </Stack>
              </Stack>
              <Stack spacing='4' minW='36'>
                <Stack spacing='3' shouldWrapChildren>
                  <Button variant='link' onClick={datenschutzOnOpen} fontWeight='md'>
                    Datenschutz
                  </Button>
                  <DatenschutzModal
                    isOpen={datenschutzIsOpen}
                    onClose={datenschutzOnClose}
                    onOpen={datenschutzOnOpen}
                  />
                </Stack>
              </Stack>

              {!adminInfo ? (
                <Stack spacing='3' shouldWrapChildren>
                  <Button variant='link' onClick={() => adminLoginOpen()} fontWeight='md'>
                    Admin
                  </Button>
                  <LoginModal isOpen={adminIsOpen} onClose={adminOnClose} onOpen={adminOnOpen} />
                </Stack>
              ) : (
                <Stack spacing='3' shouldWrapChildren>
                  <Button onClick={() => logoutHandler()} fontWeight='md'>
                    Logout
                  </Button>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Stack pt='8' pb='12' justify='space-between' direction={{ base: 'column-reverse', md: 'row' }} align='center'>
          <Text fontSize='sm' color='subtle'>
            &copy; {new Date().getFullYear()} Ramona Guhl FH, BSc.
          </Text>
          <Text fontSize='sm' color='subtle'>
            Designed by Benjamin Fischer
          </Text>

        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
