import { CheckIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { updateAktuellesEntry } from '../../redux/actions/aktuellesActions';
import TextArea from '../TextArea';
import TextField from '../TextField';

const AktuellesEntryBearbeitenModal = ({ onClose, isOpen, text, titel, id }) => {
  const dispatch = useDispatch();

  const { loading, error, updateFlag } = useSelector((state) => state.aktuelles);
  const toast = useToast();

  useEffect(() => {
    if (updateFlag) {
      setTimeout(() => {
        onClose();
      }, '1000');

    }
  }, [updateFlag, dispatch, onClose, toast]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='lg' isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pl='10'>Eintrag bearbeiten</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{ titel: titel, text: text }}
          validationSchema={Yup.object({
            titel: Yup.string()
              .min(5, 'Titel mus mindestens 5 Zeichen lang sein.')
              .required('Ein Titel ist notwendig.'),
            text: Yup.string()
              .min(5, 'Der Text muss mindestens 5 Zeichen lang sein.')
              .required('Ein Text ist notwendig.'),
          })}
          onSubmit={(values) => {
            dispatch(updateAktuellesEntry(id, values.titel, values.text));
          }}>
          {(formik) => (
            <Stack spacing='8'>
              <Box py='8' px='10'>
                <Stack spacing='6' as='form' onSubmit={formik.handleSubmit}>
                  {error && (
                    <Alert
                      status='error'
                      flexDirection='column'
                      alignItems='center'
                      justifyContent='center'
                      textAlign='center'>
                      <AlertIcon />
                      <AlertTitle>Upps!</AlertTitle>
                      <AlertDescription>{error}</AlertDescription>
                    </Alert>
                  )}

                  <Stack spacing='5'>
                    <FormControl>
                      <TextField type='text' name='titel' placeholder='Neuer Titel' label='Neuer Titel' />
                      <TextArea type='text' name='text' placeholder='Neuer Text' label='Neuer Text' />
                    </FormControl>
                  </Stack>
                  <Stack spacing='6'>
                    <Button
                      colorScheme={updateFlag ? 'green' : 'ram'}
                      fontWeight='md'
                      loadingText='...speichern'
                      size='lg'
                      fontSize='md'
                      isLoading={loading}
                      type='submit'
                      rightIcon={updateFlag ? <CheckIcon /> : null}>
                      {updateFlag ? 'Eintrag gespeichert' : 'Eintrag Speichern'}
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default AktuellesEntryBearbeitenModal;
