import { Textarea, Box } from '@chakra-ui/react';
import { useField } from 'formik';

const TextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <Textarea
        {...field}
        {...props}
        borderColor={meta.error && meta.touched ? 'red' : null}
        border={meta.error && meta.touched ? '2px solid red' : null}
      />

      {meta.touched && meta.error ? <Box color='red'>{meta.error}</Box> : null}
    </>
  );
};

export default TextArea;
