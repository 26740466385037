import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  List,
  ListItem,
  Image,
  ListIcon,
} from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';

const InfoModal = ({ isOpen, onClose, title, content, image }) => {
  return (
    <Modal size='xl' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <Image objectFit='cover' src={image} />
        <ModalCloseButton />
        <ModalBody>
          {content.map((item, index) =>
            typeof item === 'string' ? (
              <div key={index}>
                <Text py='3'>{item}</Text>
              </div>
            ) : (
              <List key={index} spacing={3}>
                {item.map((subitem, subindex) => (
                  <ListItem key={subindex} display='flex' alignItems='flex-start'>
                    <ListIcon as={MdCheckCircle} color='green.500' mt={1} mr={2} />
                    <Text flex='1'>{subitem}</Text>
                  </ListItem>
                ))}
              </List>
            )
          )}
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Schliessen
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InfoModal;
