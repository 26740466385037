import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
  rueckbildungskursInfo: JSON.parse(localStorage.getItem('vorbereitungskursInfo')) ?? [],
  kursRemovedFlag: false,
  kursNewFlag: false,
  kursUpdateFlag: false,
  kursEnrolledFlag: false,
};

const rueckbildungskursSlice = createSlice({
  name: 'rueckbildungskurs',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setRueckbildungskurs: (state, { payload }) => {
      state.rueckbildungskursInfo = payload;
      state.loading = false;
      state.error = null;
    },
    setKursRemovedFlag: (state, { payload }) => {
      state.kursRemovedFlag = payload;
    },
    setKursNewFlag: (state, { payload }) => {
      state.kursNewFlag = payload;
    },
    setKursUpdateFlag: (state, { payload }) => {
      state.kursUpdateFlag = payload;
    },
    setKursEnrolledFlag: (state, { payload }) => {
      state.kursEnrolledFlag = payload;
      state.error = null;
    },
    reset: (state) => {
      state.error = null;
      state.loading = false;
      state.kursRemovedFlag = false;
      state.kursNewFlag = false;
      state.kursUpdateFlag = false;
      state.kursEnrolledFlag = false;
    },
  },
});

export const {
  setError,
  setKursUpdateFlag,
  setKursNewFlag,
  setKursRemovedFlag,
  setLoading,
  setRueckbildungskurs,
  setKursEnrolledFlag,
  reset,
} = rueckbildungskursSlice.actions;
export default rueckbildungskursSlice.reducer;
