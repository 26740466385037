import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Wrap,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaRegPlusSquare } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import RueckbildungskursTabEntry from './RueckbildungskursTabEntry';
import NewRueckbildungskursModal from './NewRueckbildungskursModal';
import { getRueckbildungskurse } from '../../redux/actions/rueckbildungskursActions';

const RueckbildungsTab = () => {
  const { error, loading, rueckbildungskursInfo, kursRemovedFlag, updateFlag } = useSelector(
    (state) => state.rueckbildungskurs
  );
  const dispatch = useDispatch();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [initLoading, setInitLoading] = useState(true);

  useEffect(() => {
    dispatch(getRueckbildungskurse());
    if (kursRemovedFlag) {
      toast({
        title: 'Kurs wurde gelöscht.',
        description: 'Der Kurs wurde erfolgreich gelöscht.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [dispatch, toast, kursRemovedFlag]);

  useEffect(() => {
    if (updateFlag) {
      toast({
        description: 'Kurs aktualisiert.',
        status: 'success',
        isClosable: true,
      });
    }
  }, [toast, updateFlag]);

  useEffect(() => {
    if (!loading) {
      setInitLoading(false);
    }
  }, [dispatch, loading]);

  const openNewEntryModal = () => {
    onOpen();
  };

  return (
    <Box>
      {error && (
        <Alert status='error'>
          <AlertIcon />
          <AlertTitle>Upps!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      {initLoading ? (
        <Wrap justify='center'>
          <Stack direction='row' spacing='4'>
            <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
          </Stack>
        </Wrap>
      ) : (
        <Box>
          <Button colorScheme='blue' mt='4px' variant='outline' onClick={() => openNewEntryModal()} fontWeight='md'>
            <Text mr='5px'>Neuer Rückbildungskurs</Text>
            <FaRegPlusSquare />
            <NewRueckbildungskursModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
          </Button>
          <TableContainer>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>Erstellt</Th>
                  <Th>Zeitraum</Th>
                  <Th>Teilnehmer</Th>
                  <Th>Optionen</Th>
                </Tr>
              </Thead>
              <Tbody>
                {rueckbildungskursInfo.length !== 0 ? (
                  rueckbildungskursInfo.map((kurs) => <RueckbildungskursTabEntry key={kurs._id} kurs={kurs} />)
                ) : (
                  <Text mt='2'>Keine Rückbildungskurse vorhanden</Text>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default RueckbildungsTab;
