import { Badge, Button, Flex, Td, Text, Tooltip, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BsExclamationTriangle } from 'react-icons/bs';
import { MdOutlineModeEdit, MdOutlineNotStarted } from 'react-icons/md';
import { TbUsersGroup } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRueckbildungskurs, updateRueckbildungskurs } from '../../redux/actions/rueckbildungskursActions';
import RueckbildungskursTeilnehmerModal from './RueckbildungskursTeilnehmerModal';
import RueckbildungskursBearbeitenModal from './RueckbildungskursBearbeitenModal';
import { resetState } from '../../redux/actions/rueckbildungskursActions';
import { FaTrashCan } from 'react-icons/fa6';
import ConfirmModal from '../modals/ConfirmModal';
import { Icon } from '@chakra-ui/react';

const RueckbildungskursTabEntry = ({ kurs }) => {
  const { isOpen: bearbeitenModalIsOpen, onOpen: openBearbeitenModal, onClose: closeBearbeitenModal } = useDisclosure();
  const { isOpen: openTeilnehmerModal, onOpen: onOpenTeilnehmerModal, onClose: closeTeilnehmerModal } = useDisclosure();
  const { isOpen: removalModalIsOpen, onOpen: removalModalOnOpen, onClose: removalModalOnClose } = useDisclosure();

  const [localStateListener, setLocalStateListener] = useState(false);
  const { loading, kursRemovedFlag } = useSelector((state) => state.rueckbildungskurs);

  const dispatch = useDispatch();
  const toast = useToast();
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    if (localStateListener) {
      if (!kurs.aktiv) {
        toast({
          title: 'Kurs wurde aktiviert.',
          description: 'Der Kurs wurde erfolgreich aktiviert.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Kurs wurde deaktiviert.',
          description: 'Der Kurs wurde erfolgreich deaktiviert.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
      setLocalStateListener(false);
      setButtonLoading(false);
    }
  }, [kurs._id, kurs.aktiv, loading, localStateListener, toast]);

  const kursStatusSwitch = (input) => {
    setButtonLoading(true);
    setLocalStateListener(true);
    dispatch(updateRueckbildungskurs({ ...kurs, aktiv: input }));
  };

  const openTeilnehmerModalClick = () => {
    onOpenTeilnehmerModal();
  };

  const edit = () => {
    dispatch(resetState());
    openBearbeitenModal();
  };

  const remove = async () => {
    removalModalOnOpen();
    dispatch(resetState());
  };

  return (
    <Tr key={kurs._id}>
      <Td>
        <Flex direction='column'>
          {kurs.aktiv ? (
            <Badge colorScheme='green' mt='2' width='45px'>
              Aktiv
            </Badge>
          ) : (
            <Badge colorScheme='red' mt='2' width='61px'>
              Inaktiv
            </Badge>
          )}
          <Text>{new Date(kurs.createdAt).toLocaleDateString('de-DE')}</Text>
        </Flex>
      </Td>
      <Td>
        {new Date(kurs.beginn).toLocaleDateString('de-DE').substring(0, 4)} -{' '}
        {new Date(kurs.ende).toLocaleDateString('de-DE')}{' '}
      </Td>
      <Td>
        {kurs.teilnehmer.length}/{kurs.maxTeilnehmerAnzahl}
        {kurs.teilnehmer.length === kurs.maxTeilnehmerAnzahl && (
          <Badge colorScheme='yellow' ml='2' width='91px'>
            Ausgebucht
          </Badge>
        )}
      </Td>

      <Td>
        <Flex direction='column'>
          <Button width='220px' colorScheme='ram' variant='outline' onClick={openTeilnehmerModalClick} fontWeight='md'>
            <Icon as={TbUsersGroup} mr='2' boxSize={6} />
            Teilnehmer
          </Button>

          <RueckbildungskursTeilnehmerModal
            isOpen={openTeilnehmerModal}
            onClose={closeTeilnehmerModal}
            onOpen={onOpenTeilnehmerModal}
            kurs={kurs}
          />

          <Button mt='2' width='220px' colorScheme='green' variant='outline' onClick={() => edit()} fontWeight='md'>
            <Icon as={MdOutlineModeEdit} mr='2' boxSize={6} />
            Bearbeiten
          </Button>

          <RueckbildungskursBearbeitenModal
            isOpen={bearbeitenModalIsOpen}
            onClose={closeBearbeitenModal}
            onOpen={openBearbeitenModal}
            kurs={kurs}
          />

          {kurs.aktiv ? (
            <Flex>
              <Tooltip label='Bei Deaktivierung wird der Kurs unter Rückbildung nicht mehr angezeigt und neue Besucher dieser Website koennen sich nicht mehr anmelden.'>
                <Button
                  width='220px'
                  colorScheme='blue'
                  mt='2'
                  variant='outline'
                  onClick={() => kursStatusSwitch(false)}
                  loadingText='Wird deaktiviert...'
                  spinnerPlacement='start'
                  isLoading={buttonLoading}
                  fontWeight='md'>
                  <BsExclamationTriangle />
                  <Text ml='5px'>Deaktivieren</Text>
                </Button>
              </Tooltip>
            </Flex>
          ) : (
            <Button
              width='220px'
              colorScheme='blue'
              mt='2'
              variant='outline'
              onClick={() => kursStatusSwitch(true)}
              loadingText='Wird aktiviert...'
              spinnerPlacement='start'
              isLoading={buttonLoading}
              fontWeight='md'>
              <MdOutlineNotStarted />
              <Text ml='5px'>Aktivieren</Text>
            </Button>
          )}
          <Button mt='2' width='220px' colorScheme='red' variant='outline' onClick={() => remove()} fontWeight='md'>
            <Icon as={FaTrashCan} mr='2' />
            Löschen
          </Button>
          <ConfirmModal
            isOpen={removalModalIsOpen}
            onClose={removalModalOnClose}
            action={deleteRueckbildungskurs}
            btnText={'Kurs Loeschen'}
            id={kurs._id}
            title={'Möchtest du wirklich diesen Kurs inklusive eventuellen Teilnehmern löschen?'}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default RueckbildungskursTabEntry;
