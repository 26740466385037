import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Button, Image, Stack, Text } from '@chakra-ui/react';

const AngebotsCard = ({ image, title, text, children, onOpen }) => {
  const mehrErfahrenClick = () => {
    onOpen();
  };

  return (
    <Box
      transition='transform 0.3s ease-in-out'
      _hover={{
        transform: 'scale(1.1)',
      }}
      boxShadow='2xl'
      fontFamily="'Open Sans', sans-serif"
      bg='#B284C2'
      width='300px'
      height='500px'
      rounded='md'>
      <Image src={image} rounded='md' height='200px' width='300px' />
      <Stack textAlign='center' p='4' color='white' spacing='10'>
        <Text fontSize='xl'>{title}</Text>
        <Text noOfLines={4} fontSize='sm' fontWeight='light-bold'>
          {text}
        </Text>
        <Button
          rightIcon={<ArrowForwardIcon />}
          onClick={() => mehrErfahrenClick()}
          textColor='white'
          variant='outline'
          fontWeight='medium'
          _hover={{ background: '#8D56A0' }}>
          Mehr erfahren
        </Button>
        {children}
      </Stack>
    </Box>
  );
};

export default AngebotsCard;
