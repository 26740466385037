import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Element } from 'react-scroll';
import Angebot from './components/Angebot';
import Footer from './components/Footer';
import Kontakt from './components/Kontakt';
import Navbar from './components/Navbar';
import UeberMich from './components/UeberMich';
import Vertretung from './components/Vertretung';
import AdminConsoleScreen from './screens/AdminConsoleScreen';
import LandingScreen from './screens/LandingScreen';
import Aktuelles from './components/Aktuelles';
import DocumentsDownload from './components/DocumentsDownload';
import NotFoundPage from './screens/NotFoundScreen';

function App() {
  const theme = extendTheme({
    fonts: { heading: `'Heading Font Name', sans-serif`, body: `'Poppins', sans-serif;` },
    colors: {
      ram: {
        50: '#FDFAFE',
        100: '#EEDEF3',
        200: '#B284C2',
        300: '#D4B4DF',
        400: '#8D56A0',
        500: '#a47bc6',
        600: '#7d4da8',
        700: '#5b2d7c',
        800: '#3f1859',
      },
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Navbar />
        <main>
          <Routes>
            <Route
              path='/'
              element={
                <LandingScreen>
                  <Element name='aktuelles'>
                    <Aktuelles />
                  </Element>
                  <Element name='ueber-mich'>
                    <UeberMich />
                  </Element>
                  <Element name='angebot'>
                    <Angebot />
                  </Element>
                  <Element name='kontakt'>
                    <Kontakt />
                  </Element>
                  <Element name='vertretung'>
                    <Vertretung />
                  </Element>
                  <Element name='dokumente'>
                    <DocumentsDownload />
                  </Element>
                </LandingScreen>
              }
            />
            <Route path='/admin-console' element={<AdminConsoleScreen />} />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </ChakraProvider>
  );
}

export default App;
