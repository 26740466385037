import { Box, Container, Fade, Text, VStack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { scrollSpy } from 'react-scroll';
import './styles.css';
import { Helmet } from 'react-helmet-async';

const LandingScreen = ({ children }) => {
  useEffect(() => {
    scrollSpy.update();
  }, []);
  return (
    <Box className='hide-scrollbar'>
      <Helmet>
        <title>Hebamme Ramona Guhl</title>
        <meta
          name='description'
          content='Hier erfährst du persönliches über mich als Person aber auch als Hebamme...'
        />
        <link rel='canonical' href='/' />
      </Helmet>
      <VStack>
        <Box
          p='2'
          position='relative'
          h={'100vh'}
          backgroundImage='url(/images/foot.jpg)'
          bgSize='cover'
          bgPosition='center'
          bgRepeat='no-repeat'
          left={0}
          right={0}
          width='100%'
          maxWidth='100%'>
          <Box
            position='absolute'
            top={0}
            left={0}
            w='full'
            h='full'
            bg='#d4b4df'
            opacity={0.3}
            bgBlendMode='multiply'
          />
          <Fade position='relative' in={true} transition={{ enter: { duration: 2, delay: 0.5 } }}>
            <Container
              position='relative'
              maxW='7xl'
              zIndex={0}
              textAlign='start'
              display='flex'
              justifyContent='flex-end'
              pt='32'>
              <Text fontSize={{ base: 'xl', md: '3xl' }} maxW='500px' pr='10'>
                Mit jedem Menschen ist etwas Neues in die Welt gesetzt, was es nocht nicht gegeben hat, etwas Erstes und
                Einziges.
                <br />
                <Text as='i' fontSize='md'>
                  Martin Buber
                </Text>
              </Text>
            </Container>
          </Fade>
        </Box>
      </VStack>
      {children}
    </Box>
  );
};

export default LandingScreen;
