import { Button, Flex, Td, Text, Textarea, Tr, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdOutlineModeEdit } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { aktuellesRemovalFlag, aktuellesUpdateFlag, deleteAktuelles } from '../redux/actions/aktuellesActions';
import AktuellesEntryBearbeitenModal from './modals/AktuellesEntryBearbeitenModal';

const AktuellesTabEntry = ({ info }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const [removeLoading, setRemoveLoading] = useState(false);

  const removeAktuelles = (id) => {
    setRemoveLoading(true);
    dispatch(deleteAktuelles(id));
    dispatch(aktuellesRemovalFlag(false));
  };

  const edit = () => {
    dispatch(aktuellesUpdateFlag(false));
    onOpen();
  };

  return (
    <Tr key={info._id}>
      <Td>{new Date(info.createdAt).toLocaleDateString('de-DE')}</Td>
      <Td>{info.titel}</Td>
      <Td>
        <Textarea disabled w='270px' h='120px' value={info.text} />
      </Td>
      <Td>
        <Flex direction='column'>
          <Button width='220px' colorScheme='green' variant='outline' onClick={() => edit()} fontWeight='md'>
            <MdOutlineModeEdit mr='5' />
            Bearbeiten
          </Button>
          <AktuellesEntryBearbeitenModal
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            titel={info.titel}
            text={info.text}
            id={info._id}
          />
          <Button
            width='220px'
            fontWeight='md'
            colorScheme='red'
            mt='4px'
            variant='outline'
            onClick={() => removeAktuelles(info._id)}
            loadingText='Wird geloescht...'
            spinnerPlacement='start'
            isLoading={removeLoading}>
            <RiDeleteBin6Line />
            <Text ml='5px'>Löschen</Text>
          </Button>
        </Flex>
      </Td>
    </Tr>
  );
};

export default AktuellesTabEntry;
