import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  error: null,
  adminInfo: JSON.parse(localStorage.getItem('adminInfo')) ?? null,
  passwordChange: false,
  tokenValid: null,
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
      state.passwordChange = false;
    },
    adminLogin: (state, { payload }) => {
      state.adminInfo = payload;
      state.error = null;
      state.loading = false;
      state.passwordChange = false;
      state.tokenValid = true;
    },
    adminLogout: (state) => {
      state.loading = false;
      state.error = null;
      state.adminInfo = null;
      state.passwordChange = false;
      state.tokenValid = false;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setPasswordChange: (state, { payload }) => {
      state.error = null;
      state.loading = false;
      state.passwordChange = payload;
    },
    reset: (state) => {
      state.error = null;
      state.loading = false;
      state.passwordChange = false;
    },
    setTokenState: (state, { payload }) => {
      state.tokenValid = payload;
      state.loading = false;
    },
  },
});

export const { setError, setLoading, adminLogin, adminLogout, setPasswordChange, reset, setLoggedIn, setTokenState } =
  adminSlice.actions;

export default adminSlice.reducer;

export const adminSelector = (state) => state.admin;
