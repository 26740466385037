import { PhoneIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react';
import emailjs from 'emailjs-com';
import { Field, Formik } from 'formik';
import { GrSend } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';

const KontaktForm = () => {
  const [emailjsInfo, setEmailjsInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const credentials = async () => {
      const { data } = await axios.get('/api/config/emailjs');
      setEmailjsInfo(data);
    };
    credentials();
  }, []);

  const sendEmail = (values, reset) =>
    emailjs
      .send(
        emailjsInfo.service_id,
        emailjsInfo.template_id,
        { ...values, et: new Date(values.et).toLocaleDateString('de-DE') },
        emailjsInfo.public_key
      )
      .then((res) => {
        setLoading(false);
        toast({
          title: 'Nachricht gesendet.',
          description: 'Danke vielmals. Ich melden mich so schnell wie möglich',
          status: 'success',
          duration: 7000,
          isClosable: true,
        });
        reset();
        setTimeout(() => {
          navigate('/', { replace: true });
        }, '1000');
      })
      .catch((error) => {
        setLoading(false);
        toast({
          title: 'Uups!',
          description: 'Da ist ein Fehler unterlaufen, refresh die Seite und probiere es bitte erneut.',
          status: 'error',
          duration: 7000,
          isClosable: true,
        });
      });

  function isValidEmail(email) {
    // eslint-disable-next-line
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }

  return (
    <>
      <Container maxW='5xl' bg='white' rounded='md' p='10' border='1px' borderColor='ram.200'>
        <Formik
          initialValues={{
            email: '',
            name: '',
            text: '',
            wohngemeinde: '',
            phone: '',
            et: '',
          }}
          onSubmit={(values, actions) => {
            setLoading(true);
            sendEmail(values, actions.resetForm);
          }}>
          {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <VStack spacing={5}>
                <FormControl isInvalid={!!errors.email && touched.email}>
                  <FormLabel htmlFor='email'>Email Addresse</FormLabel>
                  <Field
                    as={Input}
                    id='email'
                    name='email'
                    type='email'
                    variant='filled'
                    validate={(value) => {
                      let error;
                      if (!isValidEmail(value)) {
                        error = 'Email Adresse muss gueltig sein.';
                      }
                      return error;
                    }}
                  />

                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.name && touched.name}>
                  <FormLabel htmlFor='name'>Name</FormLabel>
                  <Field
                    as={Input}
                    id='name'
                    name='name'
                    type='text'
                    variant='filled'
                    validate={(value) => {
                      let error;

                      if (value.length < 2) {
                        error = 'Name ist erforderlich.';
                      }

                      return error;
                    }}
                  />
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.wohngemeinde && touched.wohngemeinde}>
                  <FormLabel htmlFor='et'>Wohngemeinde</FormLabel>
                  <Field
                    as={Input}
                    id='wohngemeinde'
                    name='wohngemeinde'
                    type='text'
                    variant='filled'
                    validate={(value) => {
                      let error;

                      if (value.length < 2) {
                        error = 'Wohngemeinde ist erforderlich.';
                      }

                      return error;
                    }}
                  />
                  <FormErrorMessage>{errors.wohngemeinde}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.phone && touched.phone}>
                  <FormLabel htmlFor='phone'>Telefon</FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                      <PhoneIcon color='gray.300' />
                    </InputLeftElement>
                    <Field
                      as={Input}
                      id='phone'
                      name='phone'
                      type='number'
                      variant='filled'
                      validate={(value) => {
                        let error;

                        if (value.length < 10) {
                          error = 'Diese Telefonnummer ist nicht gueltig.';
                        }
                        return error;
                      }}
                    />
                  </InputGroup>
                  <FormErrorMessage>{errors.phone}</FormErrorMessage>
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor='et'>Entbindungstermin (falls bekannt)</FormLabel>
                  <Field as={Input} id='et' name='et' type='date' variant='filled' />
                </FormControl>

                <FormControl isInvalid={!!errors.text && touched.text} mb='10'>
                  <FormLabel htmlFor='text'>Nachricht</FormLabel>
                  <Field
                    as={Textarea}
                    id='text'
                    name='text'
                    type='text'
                    variant='filled'
                    validate={(value) => {
                      let error;

                      if (value.length < 10) {
                        error = 'Nachricht ist erforderlich. Mindestens 10 Zeichen.';
                      }

                      return error;
                    }}
                  />
                  <FormErrorMessage>{errors.text}</FormErrorMessage>
                </FormControl>
                <Button
                  isLoading={loading}
                  loadingText='Senden...'
                  type='submit'
                  width='full'
                  rightIcon={<GrSend />}
                  fontWeight='medium'
                  colorScheme='ram'
                  _hover={{ background: '#8D56A0' }}>
                  Senden
                </Button>
              </VStack>
            </form>
          )}
        </Formik>
        <Box
          position='absolute'
          ml='-140px'
          mt='-80px'
          width='200px'
          zIndex='-1'
          height='200px'
          borderRadius='full'
          bg='#8D56A0'></Box>
      </Container>
    </>
  );
};

export default KontaktForm;
