import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { MdOutlinePassword } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, Link as ReactLink } from 'react-router-dom';
import AktuellesTab from '../components/AktuellesTab';
import PasswordChangeModal from '../components/modals/PasswordChangeModal';
import RueckbildungsTab from '../components/rueckbildungskurs/RueckbildungsTab';
import SchwangerschaftsvorbereitungsTab from '../components/vorbereitungskurs/SchwangerschaftsvorbereitungsTab';
import { IoReturnUpBackSharp } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { resetState, logout, setPasswordChangeFlag } from '../redux/actions/adminActions';
import { TbLogout2 } from 'react-icons/tb';
import { showVorberereitungskursAdmin, showRueckbildungskursAdmin } from '../config';

const AdminConsoleScreen = () => {
  const { adminInfo } = useSelector((state) => state.admin);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const dispatch = useDispatch();
  const toast = useToast();

  const [selectedTab, setSelectedTab] = useState(() => {
    return parseInt(localStorage.getItem('selectedTab')) || 0;
  });

  useEffect(() => {
    window.scrollTo({ top: 0 });
    dispatch(resetState());
  }, [adminInfo, dispatch, toast]);

  const handleTabChange = (index) => {
    setSelectedTab(index);
    localStorage.setItem('selectedTab', index);
  };

  const logoutHandler = () => {
    dispatch(logout());
    toast({
      description: 'Erfolgreich ausgeloggt.',
      status: 'success',
      isClosable: true,
    });
  };

  const openPasswordChangeModal = () => {
    onOpen();
    dispatch(setPasswordChangeFlag(false));
  };

  return adminInfo ? (
    <Box p='20px' minH='100vh'>
      <Button
        as={ReactLink}
        to='/'
        leftIcon={<IoReturnUpBackSharp />}
        colorScheme='ram'
        variant='outline'
        mr='3'
        fontWeight='md'>
        Zurück
      </Button>
      <Button
        mr='3'
        colorScheme='ram'
        fontWeight='md'
        onClick={openPasswordChangeModal}
        rightIcon={<MdOutlinePassword />}
        variant='outline'>
        Passwort ändern
      </Button>
      <Button
        colorScheme='ram'
        fontWeight='md'
        rightIcon={<TbLogout2 />}
        variant='outline'
        onClick={() => logoutHandler()}>
        Logout
      </Button>
      <PasswordChangeModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />

      <Stack direction={{ base: 'column', lg: 'row' }} align={{ lg: 'flex-start' }}>
        <Stack
          mt='8'
          pr={{ base: '0', md: '14' }}
          spacing={{ base: '8', md: '10' }}
          flex='1.5'
          mb={{ base: '12', md: 'none' }}>
          <Flex alignItems='center'>
            <Heading fontSize='2xl' fontWeight='extrabold' pr='3'>
              Admin Konsole
            </Heading>
          </Flex>

          <Tabs size='md' variant='enclosed' index={selectedTab} onChange={handleTabChange}>
            <TabList>
              <Tab>Aktuelles</Tab>
              {showVorberereitungskursAdmin && <Tab>Schwangerschaftsvorbereitung</Tab>}
              {showRueckbildungskursAdmin && <Tab>Rueckbildung</Tab>}
            </TabList>
            <TabPanels>
              <TabPanel>
                <AktuellesTab />
              </TabPanel>

              {showVorberereitungskursAdmin && (
                <TabPanel>
                  <SchwangerschaftsvorbereitungsTab />
                </TabPanel>
              )}

              <TabPanel>
                <RueckbildungsTab />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
      </Stack>
    </Box>
  ) : (
    <Navigate to='/' replace={true} state={{ from: location }} />
  );
};

export default AdminConsoleScreen;
