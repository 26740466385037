import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
  vorbereitungsKursInfo: JSON.parse(localStorage.getItem('vorbereitungsKurseInfo')) ?? [],

  kursRemovedFlag: false,
  kursNewFlag: false,
  kursUpdateFlag: false,
  kursEnrolledFlag: false,
};

const vorbereitungsKurseSlice = createSlice({
  name: 'vorbereitungsKurse',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setVorbereitungsKurse: (state, { payload }) => {
      state.vorbereitungsKursInfo = payload;
      state.loading = false;
      state.error = null;
    },
    setKursRemovedFlag: (state, { payload }) => {
      state.kursRemovedFlag = payload;
    },
    setKursNewFlag: (state, { payload }) => {
      state.kursNewFlag = payload;
    },
    setKursUpdateFlag: (state, { payload }) => {
      state.kursUpdateFlag = payload;
    },
    setKursEnrolledFlag: (state, { payload }) => {
      state.kursEnrolledFlag = payload;
      state.error = null;
    },
  },
});

export const {
  setError,
  setKursUpdateFlag,
  setKursNewFlag,
  setKursRemovedFlag,
  setLoading,
  setVorbereitungsKurse,
  setKursEnrolledFlag,
} = vorbereitungsKurseSlice.actions;
export default vorbereitungsKurseSlice.reducer;
