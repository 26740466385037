import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  Stack,
  SimpleGrid,
  Select,
  Flex,
  Text,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import TextField from '../TextField';
import {} from '@chakra-ui/react';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { enrollVorbereitungskurs, getVorbereitungsKurse } from '../../redux/actions/vorbereitungsKursActions';

function VorbereitungskursForm({ isOpen, onOpen, onClose }) {
  const dispatch = useDispatch();
  const { loading, error, vorbereitungsKursInfo, kursEnrolledFlag } = useSelector((state) => state.vorbereitungsKurse);

  const columWidth = '280px';
  const toast = useToast();

  useEffect(() => {
    dispatch(getVorbereitungsKurse());
    if (kursEnrolledFlag) {
      onClose();
      toast({
        description: 'Erfolgreich eingeschrieben!',
        status: 'success',
        isClosable: true,
      });
    }
  }, [dispatch, kursEnrolledFlag, onClose, toast]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size='full'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Anmeldung</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                fullName: '',
                email: '',
                address: '',
                phone: '',
                dob: '',
                frauenarzt: '',
                childName: '',
                childDob: '',
                otherChildren: '',
                courseDate: '',
                kursDate: '',
              }}
              validationSchema={Yup.object({
                email: Yup.string().email('Ungültige Email.').required('Email Addresse ist erforderlich.'),
                fullName: Yup.string().required('Vor & Nachname ist erforderlich.'),
                address: Yup.string().required('Adresse ist erforderlich.'),
                phone: Yup.string().required('Telefonnummer ist erforderlich.'),
                dob: Yup.string().required('Geburtsdatum ist erforderlich.'),
                courseId: Yup.string().required('Kurstermin ist erforderlich.'),
                frauenarzt: Yup.string(),
                childName: Yup.string(),
                childDob: Yup.string(),
                otherChildren: Yup.string(),
              })}
              onSubmit={(values) => {
                dispatch(
                  enrollVorbereitungskurs({
                    email: values.email,
                    fullName: values.fullName,
                    address: values.address,
                    phone: values.phone,
                    dob: values.dob,
                    frauenarzt: values.frauenarzt,
                    childName: values.childName,
                    childDob: values.childDob,
                    otherChildren: values.otherChildren,
                    courseId: values.courseId,
                  })
                );
              }}>
              {(formik) => (
                <Stack spacing='0'>
                  <Box py='8' px='0'>
                    <Stack spacing='6' as='form' onSubmit={formik.handleSubmit}>
                      {error && (
                        <Alert
                          status='error'
                          flexDirection='column'
                          alignItems='center'
                          justifyContent='center'
                          textAlign='center'>
                          <AlertIcon />
                          <AlertTitle>Upps!</AlertTitle>
                          <AlertDescription>{error}</AlertDescription>
                        </Alert>
                      )}
                      <Stack spacing='5' maxW='5xl'>
                        <FormControl>
                          <SimpleGrid spacing='4' minChildWidth={columWidth}>
                            <TextField type='text' name='fullName' placeholder='' label='Vollständiger Name*' />
                            <TextField type='text' name='address' placeholder='' label='Wohnadresse*' />
                          </SimpleGrid>
                          <SimpleGrid spacing='4' minChildWidth={columWidth}>
                            <TextField type='text' name='phone' placeholder='' label='Telefon / Natel*' />
                            <TextField type='text' name='email' placeholder='' label='Email*' />
                          </SimpleGrid>
                          <SimpleGrid spacing='4' minChildWidth={columWidth}>
                            <TextField type='date' name='dob' placeholder='' label='Geburtsdatum*' />
                            <TextField
                              type='text'
                              name='frauenarzt'
                              placeholder=''
                              label='Betr. Gynäkologe/Gynäkologin'
                            />
                          </SimpleGrid>
                          <SimpleGrid mt='2' spacing='4' minChildWidth={columWidth}>
                            <TextField type='text' name='childName' placeholder='' label='Name des Kindes' />
                            <TextField type='date' name='childDob' label='Geburtsdatum des Kindes' />
                          </SimpleGrid>
                          <SimpleGrid mt='2' spacing='4' minChildWidth={columWidth}>
                            <TextField
                              type='text'
                              name='otherChildren'
                              placeholder=''
                              label='Name und Alter der anderen Kinder'
                            />
                          </SimpleGrid>
                          <SimpleGrid spacing='4' minChildWidth={columWidth}>
                            <Flex direction='column'>
                              <Text>Kurstermin*</Text>
                              <Select
                                placeholder='Wähle deinen Kurstermin'
                                label='Kurstermin'
                                name='courseId'
                                value={formik.values.courseId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.courseDate && formik.errors.courseDate}
                                errorBorderColor='red.400'
                                border='1px'>
                                {vorbereitungsKursInfo
                                  .filter((kurs) => kurs.aktiv)
                                  .map((kurs) => (
                                    <option key={kurs._id} value={kurs._id}>
                                      {new Date(kurs.beginn).toLocaleDateString('de-DE')} -{' '}
                                      {new Date(kurs.ende).toLocaleDateString('de-DE')}
                                    </option>
                                  ))}
                              </Select>
                              <Text pt='2' pl='1' textColor='red.500' fontSize='sm'>
                                {formik.errors.courseDate}
                              </Text>
                            </Flex>
                          </SimpleGrid>
                          <SimpleGrid mt='6' spacing='4'>
                            <Button
                              colorScheme='ram'
                              fontWeight='md'
                              size='lg'
                              fontSize='md'
                              isLoading={loading}
                              type='submit'>
                              Abschicken
                            </Button>
                          </SimpleGrid>
                          <Spacer />
                        </FormControl>
                      </Stack>
                      <Stack spacing='6'></Stack>
                    </Stack>
                  </Box>
                </Stack>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default VorbereitungskursForm;
