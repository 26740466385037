import axios from 'axios';
import {
  setError,
  setLoading,
  setVorbereitungsKurse,
  setKursUpdateFlag,
  setKursEnrolledFlag,
  setKursNewFlag,
} from '../slices/vorbereitungsKurse';

export const getVorbereitungsKurse = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { data } = await axios.get('api/vorbereitungsKurs');

    dispatch(setVorbereitungsKurse(data));

    localStorage.setItem('vorbereitungsKursInfo', JSON.stringify(data));
  } catch (error) {
    dispatch(
      setError(
        error.response && error.response.data
          ? error.response.data
          : error.message
          ? error.message
          : "Ein unbekannter Fehler ist aufgetreten, probier's ein andern mal"
      )
    );
  }
};

export const kursNewFlagReset = (input) => async (dispatch) => {
  dispatch(setKursNewFlag(input));
};

export const createNewVorbereitungskurs = (vorbereitungskurs) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const {
    admin: { adminInfo },
  } = getState();

  try {
    const { token } = adminInfo;
    const config = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } };

    const { data } = await axios.post('api/vorbereitungsKurs', vorbereitungskurs, config);

    dispatch(setVorbereitungsKurse(data));
    dispatch(setKursNewFlag(true));
    localStorage.setItem('vorbereitungsKursInfo', JSON.stringify(data));
  } catch (error) {
    dispatch(
      setError(
        error.response && error.response.data
          ? error.response.data
          : error.message
          ? error.message
          : "Ein unbekannter Fehler ist aufgetreten, probier's ein andern mal"
      )
    );
  }
};

export const updateVorbereitungsKurs = (vorbereitungsKurs) => async (dispatch, getState) => {
  dispatch(setLoading(true));

  const {
    admin: { adminInfo },
  } = getState();

  try {
    const { token } = adminInfo;
    const config = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } };

    const { data } = await axios.put(`api/vorbereitungsKurs`, vorbereitungsKurs, config);

    dispatch(setVorbereitungsKurse(data));
    dispatch(setKursUpdateFlag(true));

    localStorage.setItem('vorbereitungsKursInfo', JSON.stringify(data));
  } catch (error) {
    dispatch(
      setError(
        error.response && error.response.data
          ? error.response.data
          : error.message
          ? error.message
          : "Ein unbekannter Fehler ist aufgetreten, probier's ein andern mal"
      )
    );
  }
};

export const kursUpdateFlag = (input) => async (dispatch) => {
  dispatch(setKursUpdateFlag(input));
};

export const enrollVorbereitungskurs = (teilnehmer) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    await axios.post(`api/vorbereitungsKurs/enroll`, { teilnehmer });

    dispatch(setKursEnrolledFlag(true));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(
      setError(
        error.response && error.response.data
          ? error.response.data
          : error.message
          ? error.message
          : "Ein unbekannter Fehler ist aufgetreten, probier's ein andern mal"
      )
    );
  }
};

export const kursEnrolledFlag = (input) => async (dispatch) => {
  dispatch(setKursEnrolledFlag(input));
};

export const removeVorbereitungskursTeilnehmer = (courseId, teilnehmerId) => async (dispatch, getState) => {
  dispatch(setLoading(true));

  const {
    admin: { adminInfo },
  } = getState();

  try {
    const { token } = adminInfo;
    const config = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } };

    const { data } = await axios.delete(`api/vorbereitungsKurs/remove`, {
      ...config,
      data: { courseId, teilnehmerId },
    });

    dispatch(setVorbereitungsKurse(data));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(
      setError(
        error.response && error.response.data
          ? error.response.data
          : error.message
          ? error.message
          : "Ein unbekannter Fehler ist aufgetreten, probier's ein andern mal"
      )
    );
  }
};
