import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Text,
  Flex,
} from '@chakra-ui/react';

const TeilnehmerInfoModal = ({ isOpen, onClose, teilnehmer }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{teilnehmer.fullName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justifyContent={'space-between'} p='2'>
            <Text>Angemeldet:</Text>
            <Text>{new Date(teilnehmer.createdAt).toLocaleDateString('de-DE').split('T')[0]}</Text>
          </Flex>
          <Flex justifyContent={'space-between'} p='2'>
            <Text>Email:</Text>
            <Text>{teilnehmer.email}</Text>
          </Flex>
          <Flex justifyContent={'space-between'} p='2'>
            <Text>Wohnadresse:</Text>
            <Text>{teilnehmer.address}</Text>
          </Flex>
          <Flex justifyContent={'space-between'} p='2'>
            <Text>Stadt:</Text>
            <Text>
              {teilnehmer.postalCode} {` `} {teilnehmer.city}
            </Text>
          </Flex>
          <Flex justifyContent={'space-between'} p='2'>
            <Text>Telefon</Text>
            <Text>{teilnehmer.phone}</Text>
          </Flex>
          <Flex justifyContent={'space-between'} p='2'>
            <Text>Geburtsmodus</Text>
            <Text>{teilnehmer.birthModus}</Text>
          </Flex>
          <Flex justifyContent={'space-between'} p='2'>
            <Text>Geburtsdatum</Text>
            <Text>{new Date(teilnehmer.dob).toLocaleDateString('de-DE').split('T')[0]}</Text>
          </Flex>
          <Flex justifyContent={'space-between'} p='2'>
            <Text>Geburtsdatum des Kindes</Text>
            <Text>{new Date(teilnehmer.childDob).toLocaleDateString('de-DE').split('T')[0]}</Text>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={onClose}>
            Schliessen
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TeilnehmerInfoModal;
