import { CheckIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { createNewRueckbildungskurs, kursNewFlagReset, resetState } from '../../redux/actions/rueckbildungskursActions';
import TextField from '../TextField';

const NewRueckbildungkursModal = ({ onClose, isOpen }) => {
  const dispatch = useDispatch();

  const { loading, error, kursNewFlag } = useSelector((state) => state.rueckbildungskurs);
  const toast = useToast();

  useEffect(() => {
    dispatch(resetState());
    if (kursNewFlag) {
      setTimeout(() => {
        onClose();
        dispatch(kursNewFlagReset(false));
      }, '1000');

      toast({
        description: 'Kurs gespeichert',
        status: 'success',
        isClosable: true,
      });
    }
  }, [kursNewFlag, dispatch, onClose, toast]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='lg' isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pl='10'>Neuer Rückbildungskurs</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{ beginn: new Date(), ende: new Date(), maxTeilnehmerAnzahl: 8 }}
          validationSchema={Yup.object({
            beginn: Yup.date().min(new Date(), 'Datum darf nicht in der Vergangenheit liegen').required('Pflichtfeld'),
            ende: Yup.date().min(new Date(), 'Datum darf nicht in der Vergangenheit liegen').required('Pflichtfeld'),
            maxTeilnehmerAnzahl: Yup.number().min(8, 'Mindestens 1 Teilnehmer').required('Pflichtfeld'),
          })}
          onSubmit={(values) => {
            dispatch(
              createNewRueckbildungskurs({
                beginn: values.beginn,
                ende: values.ende,
                maxTeilnehmerAnzahl: values.maxTeilnehmerAnzahl,
              })
            );
          }}>
          {(formik) => (
            <Stack spacing='8'>
              <Box py='8' px='10'>
                <Stack spacing='6' as='form' onSubmit={formik.handleSubmit}>
                  {error && (
                    <Alert
                      status='error'
                      flexDirection='column'
                      alignItems='center'
                      justifyContent='center'
                      textAlign='center'>
                      <AlertIcon />
                      <AlertTitle>Upps!</AlertTitle>
                      <AlertDescription>{error}</AlertDescription>
                    </Alert>
                  )}

                  <Stack spacing='5'>
                    <FormControl>
                      <TextField type='date' name='beginn' placeholder='Beginn' label='Beginn' />
                      <TextField type='date' name='ende' placeholder='Ende' label='Ende' />
                      <TextField
                        type='number'
                        name='maxTeilnehmerAnzahl'
                        placeholder='Maximale Teilnehmeranzahl'
                        label='Maximale Teilnehmeranzahl'
                      />
                    </FormControl>
                  </Stack>
                  <Stack spacing='6'>
                    <Button
                      colorScheme={kursNewFlag ? 'green' : 'ram'}
                      fontWeight='md'
                      loadingText='...speichern'
                      size='lg'
                      fontSize='md'
                      isLoading={loading}
                      type='submit'
                      rightIcon={kursNewFlag ? <CheckIcon /> : null}>
                      {kursNewFlag ? 'Kurs gespeichert' : 'Kurs Speichern'}
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default NewRueckbildungkursModal;
