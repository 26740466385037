import React from 'react';
import { Flex, Heading, Text, Button } from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';

const NotFoundScreen = () => {
  return (
    <Flex align='center' justify='center' minHeight='100vh' direction='column' textAlign='center'>
      <Heading as='h1' size='2xl' mb={4}>
        404 Nicht gefunden
      </Heading>
      <Text fontSize='xl' color='gray.600' mb={8}>
        Hmmm....🤔 Ich habe nicht gefunden wonach du suchst.
      </Text>
      <Text fontSize='md' color='gray.500'>
        Bitte ueberpruefe deinen URL oder gehe zur Homepage zurueck.
      </Text>
      <Button mt='4' size='lg' as={ReactLink}>Homepage</Button>
    </Flex>
  );
};

export default NotFoundScreen;
