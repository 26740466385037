import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Collapse,
  Container,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { CgMenuMotion } from 'react-icons/cg';
import { useDispatch, useSelector } from 'react-redux';
import { Link as ReactLink, useLocation } from 'react-router-dom';
import { Link as SLink } from 'react-scroll';
import { logout, isTokenValid } from '../redux/actions/adminActions';
import AdminMenu from './AdminMenu';

const links = [
  { linkName: 'Aktuelles', path: 'aktuelles' },
  { linkName: 'Über Mich', path: 'ueber-mich' },
  { linkName: 'Angebot & Kurse', path: 'angebot' },
  { linkName: 'Kontakt', path: 'kontakt' },
  { linkName: 'Vertretung', path: 'vertretung' },
  { linkName: 'Dokumente', path: 'dokumente' },
];

const ScrollLink = ({ path, children }) => (
  <Link as={SLink} activeClass='active' to={path} spy={true} smooth={true} offset={-50} duration={500}>
    {children}
  </Link>
);

const Navbar = () => {
  const location = useLocation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { adminInfo, tokenValid } = useSelector((state) => state.admin);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();
  const [loginSuccessShowed, setLoginSuccessShowed] = useState(false);

  useEffect(() => {
    setShow(true);
    dispatch(isTokenValid());
    if (!tokenValid && tokenValid !== null) {
      dispatch(logout());
    } else if (tokenValid) {
      if (!loginSuccessShowed) {
        setLoginSuccessShowed(true);
        toast({
          description: 'Login erfolgreich.',
          status: 'success',
          isClosable: true,
        });
      }
    }
  }, [dispatch, location.pathname, loginSuccessShowed, show, toast, tokenValid]);

  useEffect(() => {
    if (adminInfo) {
      dispatch(isTokenValid());
    }
  }, [adminInfo, dispatch, toast, tokenValid]);

  const logoutHandler = () => {
    setLoginSuccessShowed(false);
    dispatch(logout());
    toast({
      description: 'Erfolgreich ausgeloggt.',
      status: 'success',
      isClosable: 'true',
    });
  };

  return (
    <Collapse animateOpacity in={show} transition={{ enter: { duration: 1, delay: 2 } }}>
      <Box
        sx={{
          '&::-webkit-scrollbar': {
            width: '16px',
            borderRadius: '8px',
            backgroundColor: `rgba(0, 0, 0, 0.05)`,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(0, 0, 0, 0.05)`,
          },
        }}
        py='10'
        bg='ram.300'
        zIndex={5}>
        <Container maxW='7xl' fontSize={{ base: '2xl', sm: '2xl', md: '3xl', lg: 'xl', xl: '2xl' }}>
          <Flex h={16} alignItems='center' justifyContent='space-between'>
            <Flex alignItems='center' minWidth={{ sm: '40px' }} display={{ lg: 'none' }}></Flex>
            <HStack spacing='5'>
              <Link as={ReactLink} to='/' style={{ textDecoration: 'none' }}>
                <Flex alignItems='center' fontFamily='Courgette'>
                  <Image
                    src='./images/logo-ramona.webp'
                    boxSize={{ base: '60px', md: '70px', lg: '80px', xl: '100px' }}
                    objectFit='cover'
                  />
                  <Text pb='12' pl='2'>
                    Hebamme
                  </Text>
                  <Text minWidth='190px' noOfLines={1} pt='5' ml='-20'>
                    Ramona Guhl
                  </Text>
                </Flex>
              </Link>
              <HStack spacing={5} display={{ base: 'none', lg: 'flex' }}>
                {links.map((link) => (
                  <ScrollLink key={link.linkName} path={link.path}>
                    <Flex
                      fontSize='lg'
                      alignItems='center'
                      minWidth={
                        link.linkName === 'Über Mich' ? '101px' : link.linkName === 'Angebot & Kurse' ? '153px' : null
                      }>
                      {link.linkName}
                    </Flex>
                  </ScrollLink>
                ))}
                {adminInfo && <AdminMenu adminInfo={adminInfo} logoutHandler={logoutHandler} />}
              </HStack>
            </HStack>
            <IconButton
              bg='parent'
              icon={isOpen ? <CloseIcon /> : <CgMenuMotion size='34px' />}
              display={{ lg: 'none' }}
              onClick={isOpen ? onClose : onOpen}
              _hover={{ bg: '#d4b4df' }}
            />
          </Flex>
          {isOpen ? (
            <Box mt='4' display={{ lg: 'none' }}>
              <Stack spacing={4}>
                {links.map((link) => (
                  <ScrollLink key={link.linkName} path={link.path}>
                    {link.linkName}
                  </ScrollLink>
                ))}
                {adminInfo && <AdminMenu adminInfo={adminInfo} logoutHandler={logoutHandler} />}
              </Stack>
            </Box>
          ) : null}
        </Container>
      </Box>
    </Collapse>
  );
};

export default Navbar;
