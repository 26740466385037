import React from 'react';
import { Box, Button, Icon, Text, VStack } from '@chakra-ui/react';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { MdOutlineSimCardDownload } from 'react-icons/md';

const DownloadCard = ({ url, title }) => {
  const handleDownload = (url, filename) => {
    const link = document.createElement('a');
    link.href = `./documents/${url}`;
    link.download = filename;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Box
        height='100px'
        width='300px'
        bg='ram.200'
        clipPath='polygon(0% 0%, 70% 0%, 100% 80%, 100% 100%, 0% 100%)'></Box>
      <VStack height='300px' width='300px' bg='#EEDEF3' spacing='5'>
        <Icon fontSize='80' as={HiOutlineDocumentText} color='#8D56A0' mt='3' />
        <Text textAlign='center' fontSize='xl' textColor='#8D56A0' h='90px'>
          {title}
        </Text>
        <Button
          fontWeight='md'
          colorScheme='ram'
          onClick={() => handleDownload(url, title)}
          leftIcon={<MdOutlineSimCardDownload />}>
          Download
        </Button>
      </VStack>
    </>
  );
};

export default DownloadCard;
