import { Container, Divider, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAktuelles } from '../redux/actions/aktuellesActions';
import AktuellesCard from './AktuellesCard';

const Aktuelles = () => {
  const dispatch = useDispatch();
  const { aktuellesInfo } = useSelector((state) => state.aktuelles);

  useEffect(() => {
    dispatch(getAktuelles());
  }, [dispatch]);

  return (
    aktuellesInfo && (
      <>
        <Text fontSize='5xl' p='10' textAlign='center' fontFamily='Courgette' mt='32'>
          Aktuelles
        </Text>
        <Divider m='auto' width='60px' border='3px solid #8D56A0' />
        <Container maxW='7xl' py='10'>
          <Wrap pt='12' px='4' display='flex' justifyContent='center' spacing='10' align='center' justify='center'>
            {Array.isArray(aktuellesInfo) &&
              aktuellesInfo.map((info) => (
                <WrapItem key={info.titel}>
                  <AktuellesCard info={info} />
                </WrapItem>
              ))}
          </Wrap>
        </Container>
      </>
    )
  );
};

export default Aktuelles;
