import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  error: null,
  aktuellesInfo: [],
  aktuellesRemovedFlag: false,
  newFlag: false,
  updateFlag: false,
};

export const aktuellesSlice = createSlice({
  name: 'aktuelles',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setAktuelles: (state, { payload }) => {
      state.aktuellesInfo = payload;
      state.loading = false;
      state.error = null;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setAktuellesRemovedFlag: (state, { payload }) => {
      state.aktuellesRemovedFlag = payload;
    },
    setNewAktuellesFlag: (state, { payload }) => {
      state.newFlag = payload;
    },
    setUpdateAktuellesFlag: (state, { payload }) => {
      state.updateFlag = payload;
    },
  },
});

export const {
  setError,
  setLoading,
  setAktuelles,
  setAktuellesRemovedFlag,
  setNewAktuellesFlag,
  setUpdateAktuellesFlag,
} = aktuellesSlice.actions;

export default aktuellesSlice.reducer;

export const aktuellesSelector = (state) => state.aktuelles;
