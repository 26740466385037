import {
	Button,
	Divider,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	Text,
	VStack,
} from '@chakra-ui/react';
import React from 'react';

const ImpressumModal = ({ isOpen, onClose }) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} size='xl'>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<VStack spacing='5' p='4' minHeight='90vh'>
					<Text fontSize='5xl' fontFamily='Courgette'>
						Impressum
					</Text>
					<Divider width='60px' border='3px solid #8D56A0' />
					<Text fontSize='xl'>XXX</Text>
				</VStack>
				<ModalFooter>
					<Button onClick={onClose}>Schliessen</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ImpressumModal;
