import { Divider, Text, Wrap, WrapItem, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import AngebotsCard from './AngebotsCard';
import VorbereitungsKursInfoModal from './vorbereitungskurs/VorbereitungsKursInfoModal';
import RueckbildungsInfoModal from './rueckbildungskurs/RueckbildungsInfoModal';
import InfoModal from './InfoModal';
import {
  ktapingContent,
  wochenbettbetreuungContent,
  schwangerschaftsbetreuungContent,
  akupunkturContent,
  hebammengespraechContent,
} from './_angebots-texte';

const Angebot = () => {
  const {
    isOpen: vorbereitungsKursIsOpen,
    onOpen: vorbereitungsKursOnOpen,
    onClose: vorbereitungsKursOnClose,
  } = useDisclosure();
  const { isOpen: rueckbildungsIsOpen, onOpen: rueckbildungsOnOpen, onClose: rueckbildungsOnClose } = useDisclosure();
  const {
    isOpen: hebammengespraechIsOpen,
    onOpen: hebammengespraechOnOpen,
    onClose: hebammengespraechOnClose,
  } = useDisclosure();
  const { isOpen: ktapingIsOpen, onOpen: ktapingOnOpen, onClose: ktapingOnClose } = useDisclosure();
  const { isOpen: acupunctureIsOpen, onOpen: acupunctureOnOpen, onClose: acupunctureOnClose } = useDisclosure();
  const { isOpen: wBetreuungIsOpen, onOpen: wBetreuungOnOpen, onClose: wBetreuungOnClose } = useDisclosure();
  const { isOpen: sBetreuungIsOpen, onOpen: sBetreuungOnOpen, onClose: sBetreuungOnClose } = useDisclosure();

  return (
    <>
      <Text fontSize='5xl' p='10' textAlign='center' fontFamily='Courgette' mt='32'>
        Angebot & Kurse
      </Text>
      <Divider m='auto' width='60px' border='3px solid #8D56A0' />
      <Text fontSize='lg' textAlign='center' pt='10' px='3' py='10px'>
        Nachfolgend eine Zusammenstellung meines Angebots. Zögere nicht, mich zwecks Rückfragen zu kontaktieren. Ich
        freue mich auf dich.
      </Text>
      <Wrap pt='12' px='4' display='flex' justifyContent='center' spacing='10' align='center' justify='center'>
        <WrapItem>
          <AngebotsCard
            image='./images/pregnant-woman.jpg'
            title='Hebammengespräch'
            text={hebammengespraechContent[0]}
            onOpen={hebammengespraechOnOpen}
          />
          <InfoModal
            image='./images/pregnant-woman.jpg'
            title='Hebammengespräch'
            content={hebammengespraechContent}
            isOpen={hebammengespraechIsOpen}
            onClose={hebammengespraechOnClose}
            onOpen={hebammengespraechOnOpen}
          />
        </WrapItem>
        <WrapItem>
          <AngebotsCard
            image='./images/betreuung.jpg'
            title='Schwangerschaftbetreuung'
            onOpen={sBetreuungOnOpen}
            text={schwangerschaftsbetreuungContent[0]}
          />
          <InfoModal
            image='./images/betreuung.jpg'
            title='Schwangerschaftbetreuung'
            content={schwangerschaftsbetreuungContent}
            isOpen={sBetreuungIsOpen}
            onClose={sBetreuungOnClose}
            onOpen={sBetreuungOnOpen}
          />
        </WrapItem>
        <WrapItem>
          <AngebotsCard
            image='./images/wochenbett.jpg'
            title='Wochenbettbetreuung'
            onOpen={wBetreuungOnOpen}
            text={wochenbettbetreuungContent[0]}
          />
          <InfoModal
            image='./images/wochenbett.jpg'
            title='Wochenbettbetreuung'
            content={wochenbettbetreuungContent}
            isOpen={wBetreuungIsOpen}
            onClose={wBetreuungOnClose}
            onOpen={wBetreuungOnOpen}
          />
        </WrapItem>
        <WrapItem>
          <AngebotsCard
            image='./images/acupuncture.jpg'
            title='Akupunktur'
            onOpen={acupunctureOnOpen}
            text={akupunkturContent[0]}
          />
          <InfoModal
            image='./images/acupuncture.jpg'
            title='Akupunktur'
            content={akupunkturContent}
            isOpen={acupunctureIsOpen}
            onClose={acupunctureOnClose}
            onOpen={acupunctureOnOpen}
          />
        </WrapItem>
        <WrapItem>
          <AngebotsCard image='./images/ktaping.jpg' title='K-Taping' onOpen={ktapingOnOpen} text={ktapingContent[0]} />
          <InfoModal
            image='./images/ktaping.jpg'
            title='K-Taping'
            content={ktapingContent}
            isOpen={ktapingIsOpen}
            onClose={ktapingOnClose}
            onOpen={ktapingOnOpen}
          />
        </WrapItem>
        <WrapItem>
          <AngebotsCard
            image='./images/vorbereitung.jpg'
            title='Geburtsvorbereitung'
            text='Zurzeit biete ich Einzelkurse (mit Partner) bei mir im Praxisraum oder bei Ihnen zu Hause an. Im Geburtsvorbereitungskurs erhalten Sie Informationen zu Schwangerschaft, Geburt, Wochenbett, dem
            Stillen und die erste Zeit mit Ihrem Baby. Dazu gehört:'
            onOpen={vorbereitungsKursOnOpen}>
            <VorbereitungsKursInfoModal
              isOpen={vorbereitungsKursIsOpen}
              onClose={vorbereitungsKursOnClose}
              onOpen={vorbereitungsKursOnOpen}
            />
          </AngebotsCard>
        </WrapItem>
        <WrapItem>
          <AngebotsCard image='./images/rueckbildung.jpg' title='Rückbildung' onOpen={rueckbildungsOnOpen}  text=' Langsam sollte sich die junge Mutter wieder in ihrem Körper, der nun wieder ihr allein gehört,
                zurechtfinden. Im Rückbildungsturnen wird die körperliche Leistungsfähigkeit nach der Geburt langsam
                wieder gesteigert. Besonders intensiv trainieren wir die Beckenbodenmuskulatur, um mögliche Spätfolgen
                der Schwangerschaft (Inkontinenz, Gebärmuttersenkung) zu vermeiden. Aber auch allgemeine Kräftigungs-,
                Haltungs- und Dehnungsübungen sind Bestandteile der Lektionen, wie auch Entspannungsübungen und
                Theorieinputs.'/>
          <RueckbildungsInfoModal
            isOpen={rueckbildungsIsOpen}
            onClose={rueckbildungsOnClose}
            onOpen={rueckbildungsOnOpen}
          />
        </WrapItem>
      </Wrap>
    </>
  );
};

export default Angebot;
