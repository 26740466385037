import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	Button,
	FormControl,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { login } from '../../redux/actions/adminActions';
import PasswordField from '../PasswordField';
import TextField from '../TextField';

const LoginModal = ({ isOpen, onClose }) => {
	const dispatch = useDispatch();
	const { loading, error } = useSelector((state) => state.admin);

	return (
		<Modal isOpen={isOpen} onClose={onClose} size='lg'>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader ml='4'>Admin Login</ModalHeader>
				<ModalCloseButton />
				<Formik
					initialValues={{ email: '', password: '' }}
					validationSchema={Yup.object({
						email: Yup.string().email('Ungültige Email.').required('Email Addresse ist zwingend erforderlich.'),
						password: Yup.string()
							.min(1, 'Bitte gib das Passwort ein.')
							.required('Passwort ist zwingend erforderlich.'),
					})}
					onSubmit={(values) => {
						dispatch(login(values.email, values.password));
					}}>
					{(formik) => (
						<Stack spacing='8'>
							<Box py='8' px='10'>
								<Stack spacing='6' as='form' onSubmit={formik.handleSubmit}>
									{error && (
										<Alert
											status='error'
											flexDirection='column'
											alignItems='center'
											justifyContent='center'
											textAlign='center'>
											<AlertIcon />
											<AlertTitle>Upps!</AlertTitle>
											<AlertDescription>{error}</AlertDescription>
										</Alert>
									)}
									<Stack spacing='5'>
										<FormControl>
											<TextField type='text' name='email' placeholder='you@example.com' label='Email' />
											<PasswordField type='password' name='password' placeholder='your password' label='Password' />
										</FormControl>
									</Stack>
									<Stack spacing='6'>
										<Button colorScheme='ram' fontWeight='md' size='lg' fontSize='md' isLoading={loading} type='submit'>
											Login
										</Button>
									</Stack>
								</Stack>
							</Box>
						</Stack>
					)}
				</Formik>
			</ModalContent>
		</Modal>
	);
};

export default LoginModal;
