import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Avatar, Box, Button, Divider, Flex, Text, VStack, Wrap, WrapItem, Link } from '@chakra-ui/react';
import React from 'react';
import { PiMapPinFill } from 'react-icons/pi';

const Vertretung = () => {
  return (
    <Box px='5' mb='20'>
      <Text fontSize='5xl' p='10' textAlign='center' fontFamily='Courgette' mt='32'>
        Vertretung
      </Text>
      <Divider m='auto' width='60px' border='3px solid #8D56A0' />
      <Wrap justify='center' mt='20' spacing='20'>
        <WrapItem display='table-column'>
          <Box height='100px' width='300px' bg='#8D56A0' roundedTop='md'></Box>
          <VStack height='300px' width='300px' bg='#EEDEF3' spacing='5' roundedBottom='md'>
            <Avatar size='2xl' src='./images/dania-kaempfer.png' mt='-14' />
            <Text fontSize='xl' textColor='#8D56A0'>
              Dania Kaempfer
            </Text>
            <Flex alignItems='center' fontSize='xl'>
              <PiMapPinFill color='#8D56A0' />
              <Text fontSize='md' ml='2'>
                Othmarsingen
              </Text>
            </Flex>
            <Button
              as={Link}
              target='_blank'
              href='https://www.hebammenzauber.ch/'
              colorScheme='ram'
              rightIcon={<ArrowForwardIcon />}
              fontWeight='medium'>
              Homepage
            </Button>
          </VStack>
        </WrapItem>
        <WrapItem display='table-column'>
          <Box height='100px' width='300px' bg='#8D56A0' roundedTop='md'></Box>
          <VStack height='300px' width='300px' bg='#EEDEF3' spacing='5' roundedBottom='md'>
            <Avatar size='2xl' src='./images/lilian-gauch.webp' mt='-14' />
            <Text fontSize='xl' textColor='#8D56A0'>
              Lilian Gauch
            </Text>
            <Flex alignItems='center' fontSize='xl'>
              <PiMapPinFill color='#8D56A0' />
              <Text fontSize='md' ml='2'>
                Wohlen
              </Text>
            </Flex>
            <Button
              colorScheme='ram'
              fontWeight='medium'
              rightIcon={<ArrowForwardIcon />}
              as={Link}
              target='_blank'
              href='https://wochenbett-wohlen.ch/'>
              Homepage
            </Button>
          </VStack>
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export default Vertretung;
