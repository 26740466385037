import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Wrap,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaRegPlusSquare } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { aktuellesNewFlag, aktuellesRemovalFlag, getAktuelles } from '../redux/actions/aktuellesActions';
import AktuellesTabEntry from './AktuellesTabEntry';
import NewAktuellesEntry from './modals/NewAktuellesEntry';

const AktuellesTab = () => {
  const { error, loading, aktuellesInfo, aktuellesRemovedFlag, updateFlag } = useSelector((state) => state.aktuelles);
  const dispatch = useDispatch();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [initLoading, setInitLoading] = useState(true);

  useEffect(() => {

    dispatch(getAktuelles());
    if (aktuellesRemovedFlag) {
      toast({
        description: 'Aktuelles Eintrag geloescht.',
        status: 'success',
        isClosable: true,
      });
    }

    return () => {
      dispatch(aktuellesRemovalFlag(false));
    };
  }, [aktuellesRemovedFlag, dispatch, toast]);

  useEffect(() => {
    if (updateFlag) {
      toast({
        description: 'Eintrag aktualisiert.',
        status: 'success',
        isClosable: true,
      });
    }
  }, [toast, updateFlag]);

  useEffect(() => {
    if (!loading) {
  
      setInitLoading(false);
    }
  }, [dispatch, loading]);

  const openNewEntryModal = () => {
    dispatch(aktuellesNewFlag(false));
    onOpen();
  };

  return (
    <Box>
      {error && (
        <Alert status='error'>
          <AlertIcon />
          <AlertTitle>Upps!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      {initLoading ? (
        <Wrap justify='center'>
          <Stack direction='row' spacing='4'>
            <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
          </Stack>
        </Wrap>
      ) : (
        <Box>
          <Button colorScheme='blue' mt='4px' variant='outline' onClick={() => openNewEntryModal()} fontWeight='md'>
            <Text mr='5px'>Neuer Eintrag</Text>
            <FaRegPlusSquare />
            <NewAktuellesEntry isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
          </Button>
          <TableContainer>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>Erstellt</Th>
                  <Th>Titel</Th>
                  <Th>Text</Th>
                  <Th>Optionen</Th>
                </Tr>
              </Thead>
              <Tbody>
                {aktuellesInfo.length !== 0 ? (
                  aktuellesInfo.map((info) => <AktuellesTabEntry key={info._id} info={info} />)
                ) : (
                  <Text mt='2'>Keine Einträge vorhanden</Text>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default AktuellesTab;
