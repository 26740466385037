import { CheckIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { Formik } from 'formik'; // Add this line
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { updateRueckbildungskurs } from '../../redux/actions/rueckbildungskursActions';
import TextField from '../TextField';

const RueckbildungskursBearbeitenModal = ({ onClose, isOpen, kurs }) => {
  const dispatch = useDispatch();

  const { loading, error, kursUpdateFlag } = useSelector((state) => state.rueckbildungskurs);
  const toast = useToast();

  useEffect(() => {
    console.log(kurs);
    if (kursUpdateFlag) {
      setTimeout(() => {
        onClose();
      }, '1000');
    }
  }, [kursUpdateFlag, dispatch, onClose, toast, kurs]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='lg' isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pl='10'>Kurs bearbeiten</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            beginn: new Date(kurs.beginn).toISOString().split('T')[0],
            ende: new Date(kurs.ende).toISOString().split('T')[0],
            maxTeilnehmerAnzahl: kurs.maxTeilnehmerAnzahl,
          }}
          validationSchema={Yup.object({
            beginn: Yup.date().min(new Date(), 'Datum darf nicht in der Vergangenheit liegen').required('Pflichtfeld'),
            ende: Yup.date().min(new Date(), 'Datum darf nicht in der Vergangenheit liegen').required('Pflichtfeld'),
            maxTeilnehmerAnzahl: Yup.number().min(1, 'Mindestens 1 Teilnehmer').required('Pflichtfeld'),
          })}
          onSubmit={(values) => {
            dispatch(updateRueckbildungskurs({ ...kurs, ...values }));
          }}>
          {(formik) => (
            <Stack spacing='8'>
              <Box py='8' px='10'>
                <Stack spacing='6' as='form' onSubmit={formik.handleSubmit}>
                  {error && (
                    <Alert
                      status='error'
                      flexDirection='column'
                      alignItems='center'
                      justifyContent='center'
                      textAlign='center'>
                      <AlertIcon />
                      <AlertTitle>Upps!</AlertTitle>
                      <AlertDescription>{error}</AlertDescription>
                    </Alert>
                  )}

                  <Stack spacing='5'>
                    <FormControl>
                      <TextField type='date' name='beginn' placeholder='Beginn' label='Beginn' />
                      <TextField type='date' name='ende' placeholder='Ende' label='Ende' />
                      <TextField
                        type='number'
                        name='maxTeilnehmerAnzahl'
                        placeholder='Maximale Teilnehmeranzahl'
                        label='Maximale Teilnehmeranzahl'
                      />
                    </FormControl>
                  </Stack>
                  <Stack spacing='6'>
                    <Button
                      colorScheme={kursUpdateFlag ? 'green' : 'ram'}
                      fontWeight='md'
                      loadingText='...speichern'
                      size='lg'
                      fontSize='md'
                      isLoading={loading}
                      type='submit'
                      rightIcon={kursUpdateFlag ? <CheckIcon /> : null}>
                      {kursUpdateFlag ? 'Eintrag gespeichert' : 'Eintrag Speichern'}
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default RueckbildungskursBearbeitenModal;
